import React from 'react';
import LeftContainer from './LeftContainer';
import HomeContainer from './HomeContainer';

import './MainContainer.css';

export default function MainContainer(props) {
    return (
        <div className={`MainContainer ${props.theme === "light" ? 'light-background-layer2' : 'dark-background-layer2'}`}>
            <LeftContainer theme={props.theme} />
            <HomeContainer theme={props.theme} instanceType={props.instanceType}/>
        </div>
    )
}