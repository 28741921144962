import {getEmployeeIDFromDropdown} from '../Classes/ParseLink'

import EmployeeIcon from '../DisplayWidgets/EmployeeIcon';

function TableEmployees(data) {
    if (!data) return null;

    console.log(data)

    const employeeData = {
        headers: ["", "Last name", "First name", "Email address", "Phone number", "Home address", "Employee type", "Employee rate"],
        title: "Employees",
        entries: [],
    };

    for (const [key, value] of Object.entries(data)) {
        var initials = '';
        var fullName = '';

        if (value['LastName'] && value['FirstName']) {
            initials = value['FirstName'].charAt(0) + value['LastName'].charAt(0);
            fullName = value['FirstName'] + " " + value['LastName'];
        }

        employeeData.entries.push({
            '': <EmployeeIcon initials={initials} fullName={fullName} employeeId={key} />,
            'Last name': value['LastName'],
            'First name': value['FirstName'],
            'Email address': value['Email'],
            'Phone number': value['Phone'],
            'Home address': value['Address'],
            'Employee type': value['Type'] ? 'Hourly' : 'Salary',
            'Employee rate': '$' + value['Rate'],
        });
    }

    return employeeData;
}

function TableActivities(data, employeeData) {
    console.log(data)
    if (!data) {
        return {
            headers: ["Employee", "Start date", "End date", "Activity type", "Rate", "Hours"],
            title: "Activities",
            entries: [],
        };
    };

    console.log(data)

    const activityData = {
        headers: ["Employee", "Start date", "End date", "Activity type", "Rate", "Hours"],
        title: "Activities",
        entries: [],
    };



    for (const [key, value] of Object.entries(data)) {
        console.log(value['Employee'])
        console.log(getEmployeeIDFromDropdown(value['Employee']))
        console.log(employeeData)

        var initials = '';
        var fullName = '';
        var employeeId = null;

        if (value['Employee'] && employeeData[getEmployeeIDFromDropdown(value['Employee'])]) {
            const employee = employeeData[getEmployeeIDFromDropdown(value['Employee'])]
            initials = employee['First name'].charAt(0) + employee['Last name'].charAt(0);
            fullName = employee['First name'] + " " + employee['Last name'];
            employeeId = getEmployeeIDFromDropdown(value['Employee']);
        }

        activityData.entries.push({
            'Employee': <EmployeeIcon initials={initials} fullName={fullName} employeeId={employeeId} />,
            'Start date': value['Start date'],
            'End date': value['End date'],
            'Activity type': value['Activity type'],
            'Rate': '$' + value['Rate'],
            'Hours': value['Hours'],
        });
    }

    return activityData;
}

function TableInvoices(data) {
    console.log(data)
    if (!data) {
        return {
            headers: ["Invoice number", "", "Invoice date", "Due date", "Amount", "Status"],
            title: "Invoices",
            entries: [],
        };
    };

    console.log(data)

    const invoiceData = {
        headers: ["Invoice number", "", "Invoice date", "Due date", "Amount", "Status"],
        title: "Invoices",
        entries: [],
    };

    for (const [key, value] of Object.entries(data)) {
        var fileName = '';

        if (value && value['Invoice number']) {
            var invoiceNumber = Number(value['Invoice number']);
            if (invoiceNumber < 10) {
                fileName = "00" + invoiceNumber;
            } else if (invoiceNumber < 100) {
                fileName = "0" + invoiceNumber;
            }

            fileName = "INV-" + fileName + ".pdf";
        }

        invoiceData.entries.push({
            'Invoice number': value['Invoice number'],
            '': fileName,
            'Invoice date': value['Invoice date'],
            'Due date': value['Due date'],
            'Amount': value['Amount'],
            'Status': value['Status'],
        });
    }

    return invoiceData;
}


export {TableEmployees, TableActivities, TableInvoices};