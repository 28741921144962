import React, {useState, useEffect} from 'react';
import './InfoContainer.css';

import { useTheme } from '../ThemeContext';
import { useNavigate } from "react-router-dom";

import getIDFromLink from '../Classes/ParseLink';

export default function WorkspaceContainer(props) {
    const { theme } = useTheme();

    const navigate = useNavigate();

    const [instanceElements, setInstanceElements] = useState([]);

    useEffect(() => {
        const newElements = props.instances
        ? Object.entries(props.instances).filter(([key, value]) => key !== 'title').map(([key, value]) => {
            const subElements = Object.entries(value)
                .filter(([subKey, subValue]) => subKey !== 'dtitle' && subKey !== 'divider')
                .map(([subKey, subValue]) => (
                <div className='InfoItemContainer' key={subKey}>
                    <div className='InfoKey'>{subKey}:</div>
                    <div className='InfoValue'>{subValue}</div>
                </div>
                ));
            return value.divider ? (
                <>
                <div className='SubTitle' key={key}> {value.dtitle} </div>
                <div className={`InfoDivider ${theme === "light" ? 'light-background-layer2' : 'dark-background-color-border'}`} key={key}>
                </div>
                {subElements}
                </>
            ) : subElements;
            })
        : null;



        console.log(newElements)

        setInstanceElements(newElements);
    }, [props.instances, theme]);

    return (
        <div className={`InfoContainer ${theme === "light" ? 'light-background-layer3' : 'dark-background-layer3'}`}>
            <div className='InfoTitle'>{props.instances ? props.instances.title : ''}</div>
            <div className={`Divider ${theme === "light" ? 'light-background-layer2' : 'dark-background-color-border'}`} />
            {instanceElements}
        </div>
    )
}