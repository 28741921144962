import InvoicePdfInput from "../InputWidgets/InvoicePdfInput";
import { useTheme } from "../ThemeContext";

export default function CreateInvoice(props) {
    const { theme } = useTheme();

    return (
        <div className="CreateInvoiceContainer">
            <InvoicePdfInput items={props.items}/>
        </div>
    )
}