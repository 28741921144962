import React, { useState, useEffect } from 'react';

import './Login.css';

import { GoogleLogin, useGoogleOneTapLogin} from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Topbar from '../../Topbar';

import {auth} from '../../API/DataRequests';

import { useNavigate } from "react-router-dom";

import axios from 'axios';
import jwt_decode from "jwt-decode";

import { useTheme } from '../../ThemeContext';

import TextInput from '../../InputWidgets/TextInput';
import TelephoneInput from '../../InputWidgets/TelephoneInput';
import GetTelephoneValue from '../../InputWidgets/TelephoneInputValue';

const apiKey = process.env.REACT_APP_CLIENT_ID;

const apiEndpointGoogle = 'http://localhost:3001/api/login/google';
const apiLoginEndpoint = 'http://localhost:3001/api/login';
const apiSignUpEndpoint = 'http://localhost:3001/api/signup';
const apiVerifyEndpoint = apiSignUpEndpoint + '/verify';

export default function Login(props) {
    const { theme } = useTheme();

    const navigate = useNavigate();

    const [verify, setVerify] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [loginError, setLoginError] = useState('');

    function verifySignUp() {
        const payload = {
            email: email,
            password: password,
            phone: phone,
            code: document.getElementById('verification-code-login-page').value
        }

        axios.post(apiVerifyEndpoint, payload, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        })
        .then(response => {
            console.log('Server response:', response.data);
            navigate("/web");
        })
        .catch(error => {
            console.error('Error:', error);

        });
    }

    function handleSignIn(credentialResponse){
        console.log(credentialResponse)
        const token = credentialResponse.credential;
        const user = jwt_decode(token)
        user.token = token;
        console.log(user)

        const payload = {
        token,
        };
        axios.post(apiEndpointGoogle, payload, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        })
        .then(response => {
        console.log('Server response:', response.data);
        navigate("/web");
        })
        .catch(error => {
        console.error('Error:', error);

        });                          
    }

    function signIn() {
        setLoginError('');

        var endpoint = apiSignUpEndpoint

        if (props.login) {
            endpoint = apiLoginEndpoint;
        }

        setEmail(document.getElementById('email-login-page').value);
        setPassword(document.getElementById('password-login-page').value);

        const payload = {
            email: document.getElementById('email-login-page').value,
            password: document.getElementById('password-login-page').value
        }

        if (!props.login) {
            const phoneValue = GetTelephoneValue('phone-login-page');

            if (phoneValue.length !== 12) {
                setLoginError(phoneValue);
                return;
            }

            payload.phone = phoneValue;
            setPhone(phoneValue);
        }

        axios.post(endpoint, payload, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        })
        .then(response => {
            console.log('Server response:', response.data);
            if (response.data === 'success') {
                navigate("/web");
            } else if (response.data === 'verify') {
                setVerify(true);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            if (!error.response) {
                setLoginError('Server Error. Please try again later.');
                return;
            }

            if (error.response.data === 'UserNotConfirmed') {
                setVerify(true);
            } else if (error.response.data === 'NotAuthorized') {
                setLoginError('Email or Password is incorrect. Please try again.');
            } else if (error.response.data === 'UsernameExists') {
                setLoginError('That email is already in use. Please Sign in.');
            }
        });
    }

    useEffect(() => {
        // if (user) {
        //     navigate("/web");
        // }

        // if (!user) {
        //     auth().then((response) => {
        //         if (response === 'success') {
        //             navigate("/web");
        //         }
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });

        // }
    }, []);

    return (
        <>
        <Topbar domain="/" setLightMode={props.setLightMode} />
        <div className={`LoginContainer ${theme === "light" ? 'light-background-layer3 ' : 'dark-background-layer3'}`}>
            <div className="LoginTitle">{props.login ? 'Welcome Back' : 'Get Started'}</div>
            {/* <img src={SignUpImage} alt="signup" className="LoginImage"></img> */}
            <div className={`LoginBox ${theme === "light" ? 'light-background-layer2' : 'dark-background-layer2'}`}>
                <div className='SignInTitle'>
                    {props.login ? 'Login to Structure In' : 'Sign Up for Structure In'}
                </div>

                {!verify && <div className={`SignInContainer ${theme === "light" ? 'light-background-layer2' : 'dark-background-layer2'}`}>
                    <TextInput id='email-login-page' type="text" label="Email" theme={theme}/>
                    <TextInput id='password-login-page' type="password" label="Password" hideText={true} theme={theme}/>
                    {!props.login && <TelephoneInput id='phone-login-page' type='tel' label="Phone Number" theme={theme}/>}
                    <button onClick={signIn} className={`PopupButton ${theme === "light" ? 'light-theme-color-orange light-theme-color-orange-hover light-theme-color-orange-active' : 'dark-theme-color-orange light-theme-color-orange-hover light-theme-color-orange-active'}`}>{props.login ? 'Sign In' : 'Create account'}</button>
                </div>}

                {verify && <div className='SubDetails'>
                    Please enter the code sent to your email to verify your account.
                </div>}

                {loginError !== '' && <div className='LoginError'> 
                    {loginError}
                </div>}

                {!verify && props.login && <div className='SubDetails'>
                    Don't have an account? <a className='loginlink' href='/signup'>Sign Up</a>    
                </div>}

                {!verify && !props.login && <div className='SubDetails'>
                    Already have an account? <a className='loginlink' href='/login'>Sign In</a>    
                </div>}

                {verify && <div className={`SignInContainer ${theme === "light" ? 'light-background-layer2' : 'dark-background-layer2'}`}>
                    <TextInput id='verification-code-login-page' type='text' label="Verification Code" theme={theme}/>
                    <button onClick={verifySignUp} className={`PopupButton ${theme === "light" ? 'light-theme-color-orange light-theme-color-orange-hover light-theme-color-orange-active' : 'dark-theme-color-orange light-theme-color-orange-hover light-theme-color-orange-active'}`}>Submit</button>
                </div>}
            
                {!verify && <div className='SignInDivider'> 
                    <div className={`DividerLine ${theme === "light" ? 'light-background-divider' : 'dark-background-divider'}`}></div>
                    <div className='DividerText'>{props.login ? 'or Sign in with' : 'or Sign up with'}</div>
                    <div className={`DividerLine ${theme === "light" ? 'light-background-divider' : 'dark-background-divider'}`}></div>
                </div>}

                {!verify && <GoogleOAuthProvider clientId={apiKey}>
                    <GoogleLogin
                    onSuccess={credentialResponse => {
                        handleSignIn(credentialResponse);
                    }}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                    useOneTap
                    />
                </GoogleOAuthProvider>}
            </div>
        </div>
        </>
    )
}