import projectImage from "../images/projectsImage.png";
import employeeImage from "../images/profileIcon.png";

import {getIDFromLink} from "../Classes/ParseLink";


const addProjectMaterial = {
    'page1': [
        {
            label: "Project name",
            divider: false,
            type: "text",
        },
        {
            label: "Project address",
            divider: false,
            type: "text",
        },
        {
            label: "Project description",
            divider: false,
            type: "description",
        },
        {
            label: "Project type",
            divider: false,
            type: "options",
            options: [
                { label: "Hourly" },
                { label: "Fixed" }
            ]
        },
        {
            label: "Project start date",
            divider: false,
            type: "date",
        }
    ]
}

const addCompanyMaterial = {
    'page1': [
        {
            label: "Company name",
            divider: false,
            type: "text",
        },
        {
            label: "Company email address",
            divider: false,
            type: "text",
        },
        {
            label: "Company address",
            divider: false,
            type: "text",
        },
        {
            label: "Company phone number",
            divider: false,
            type: "text",
        }
    ],
    'page2' : [
        {
            label: "Tax classification",
            divider: false,
            type: "dropdownlist",
            options: [
                { label: 'Sole Proprietorship (Form 1040)' },
                { label: 'Partnership (GP) (Form 1065)' },
                { label: 'Limited Partnership (LP) (Form 1065)' },
                { label: 'Limited Liability Partnership (LLP) (Form 1065)' },
                { label: 'Multi-Member LLC (Form 1065)' },
                { label: 'Single Member LLC (Form 1040 Schedule C)' },
                { label: 'Corporation (C Corp) (Form 1120)' },
                { label: 'S Corporation (Form 1120S)' },
                { label: 'Limited Liability Partnership (LLP) (Form 1065)' },
                { label: 'Professional Corporation (PC) (Form 1120)' },
                { label: 'Cooperative (Co-op) (Form 1120-C)' },
                { label: 'Joint Venture (Form 1065)' },
                { label: 'Nonprofit Organization (Form 990)' },
            ] 
        },
        {
            label: "EIN",
            divider: false,
            type: "text",
        }
    
    ]
}

const addEmployeeMaterial = {
    'page1': [
        {
            label: "First name",
            divider: false,
            type: "text",
        },
        {
            label: "Last name",
            divider: false,
            type: "text",
        },
        {
            label: "Email address",
            divider: false,
            type: "text",
        },
        {
            label: "Home address",
            divider: false,
            type: "text",
        },
        {
            label: "Phone number",
            divider: false,
            type: "phone",
        },
        {
            label: "Employee type",
            divider: false,
            type: "options",
            options: [
                { label: "Hourly" },
                { label: "Salary" }
            ]
        },
        {
            label: "Employee rate",
            divider: false,
            type: "number",
        },
    ]
}

function addActivityMaterial(userData) {
    console.log(userData)
    const employeeData = []
    if (userData && userData['companies'] && userData['companies'][getIDFromLink("companies")] && userData['companies'][getIDFromLink("companies")]['employees']) {
        for (const [key, value] of Object.entries(userData['companies'][getIDFromLink("companies")]['employees'])) {
            employeeData.push({
                label: value['FirstName'] + " " + value['LastName'] + " (" + key + ")"
            });
        }
    }

    const addActivityMaterial = {
        'page1': [
                {
                    label: "Start date",
                    divider: false,
                    type: "datetime",
                },
                {
                    label: "End date",
                    divider: false,
                    type: "datetime",
                },
                {
                    label: "Employee",
                    divider: false,
                    type: "dropdownlist",
                    options: employeeData
                },
                {
                    label: "Billable",
                    divider: false,
                    type: "options",
                    options: [
                        { label: "Billable" },
                        { label: "Not Billable" }
                    ]
                },
                {
                    label: "Activity type",
                    divider: false,
                    type: "options",
                    options: [
                        { label: "Hourly" },
                        { label: "Flat Fee" }
                    ]
                },
                {
                    label: "Rate",
                    divider: false,
                    type: "number",
                },
                {
                    label: "Hours",
                    divider: false,
                    type: "number",
                },
                {
                    label: "Description",
                    divider: false,
                    type: "description",
                },
            ]
    }

    return addActivityMaterial;
}

function addInvoiceMaterial(userData) {
    const invoiceItems = []

    const addInvoiceMaterial = { 
        'page1': [
            {
                label: "",
                divider: false,
                type: "invoice",
                items: invoiceItems
            },
        ]
    }

    if (userData && userData['companies'] && userData['companies'][getIDFromLink("companies")] && userData['companies'][getIDFromLink("companies")]['projects']) {
        if (!userData['companies'][getIDFromLink("companies")]['projects'][getIDFromLink("projects")] || !userData['companies'][getIDFromLink("companies")]['projects'][getIDFromLink("projects")]['activities']) return addInvoiceMaterial;

        for (const [key, value] of Object.entries(userData['companies'][getIDFromLink("companies")]['projects'][getIDFromLink("projects")]['activities'])) {
            if (value['Billable']) {
                invoiceItems.push({
                    description: value['Description'],
                    quantity: (value['Activity type'] === 'Hourly') ? value['Hours'] : 1,
                    unitPrice: value['Rate']
                });
            }
        }
    }

    addInvoiceMaterial['page1'][0].items = invoiceItems;

    return addInvoiceMaterial;
}


function AddObject(object) {
    const elem = document.getElementById(object);
    elem.classList.add("show-popup");
}

const EmployeeAddOptionsMaterial = [
    {
        label: "Add Employee",
        activate: () => AddObject("AddEmployee"),
        image: employeeImage
    }
];

const ProjectAddOptionsMaterial = [
    {
        label: "Add Project",
        activate: () => AddObject("AddProject"),
        image: projectImage
    }
];

const ActivityAddOptionsMaterial = [
    {
        label: "Add Activity",
        activate: () => AddObject("AddActivity"),
        image: projectImage
    }
];

const InvoiceAddOptionsMaterial = [
    {
        label: "Create Invoice",
        activate: () => AddObject("CreateInvoice"),
        image: projectImage
    }
];

function getCompanyTabs(selectTab) {
    const companyTabs = [
        {
            label: "Company Information",
            category: "info",
            selectTab: selectTab,
        },
        {
            label: "Employees",
            category: "info",
            selectTab: selectTab,
        },
        {
            label: "Financials",
            category: "info",
            selectTab: selectTab,
        },
        {
            label: "Projects",
            category: "workspace",
            selectTab: selectTab,
        }
    ];
    return companyTabs;
}

function getProjectTabs(selectTab) {
    const projectTabs = [
        {
            label: "Project Information",
            category: "info",
            selectTab: selectTab,
        },
        {
            label: "Activities",
            category: "info",
            selectTab: selectTab,
        },
        {
            label: "Files",
            category: "info",
            selectTab: selectTab,
        },
        {
            label: "Invoices",
            category: "info",
            selectTab: selectTab,
        }
    ]

    return projectTabs;
}

export { addProjectMaterial, addCompanyMaterial, addEmployeeMaterial, EmployeeAddOptionsMaterial, ProjectAddOptionsMaterial, ActivityAddOptionsMaterial, InvoiceAddOptionsMaterial, addActivityMaterial, addInvoiceMaterial, getCompanyTabs, getProjectTabs};