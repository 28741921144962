import React, {useEffect, useState} from 'react';
import './Options.css';

import { useTheme } from '../ThemeContext';

export default function Options(props) {
    const { theme } = useTheme();

    const [selectedOption, setSelectedOption] = useState(props.options.length > 0 ? props.options[0].label : null);

    const allOptions = props.options.map((data) => (
        <div className={`Option ${theme === "light" ? 'light-background-layer2' : 'dark-background-layer2'}`} id={data.label} onClick={() => SelectOption(data.label)}> 
            <div className={`OptionCircle ${theme === "light" ? 'dark-border' : 'light-border'}`}> 
                <div className={`InnerCircle ${selectedOption === data.label ? 'dark-theme-color-orange' : ''}`} />
            </div>
            {data.label} 
        </div>
    ));

    function SelectOption(id) {
        setSelectedOption(id);
        props.OptionSelected(props.id, id);
    }

    useEffect(() => {
        if (selectedOption !== null) {
          props.OptionSelected(props.id, selectedOption);
        }
      }, []);

    return (
        <>
        <div className={`OptionsTitle ${theme === "light" ? 'light-background-layer2' : 'dark-background-layer2'}`}> {props.title} </div>
        <div className={`OptionsContainer ${theme === "light" ? 'light-background-layer2' : 'dark-background-layer2'}`}>
            {allOptions}
        </div>
        </>
    )
}