import React, {useState} from 'react';
import './LeftContainer.css';

import { useTheme } from '../../ThemeContext';

import AddMenu from './AddMenu';
import LeftHierarchy from './LeftHierarchy';

import projectImage from '../../images/projectsImage.png';
import companyImage from '../../images/company.png';
import employeeImage from '../../images/profileIcon.png';

export default function LeftContainer() {
    const [MenuVisible, setMenuVisible] = useState(false);

    function AddObject(object) {
        const elem = document.getElementById(object);
        elem.classList.add("show-popup");
    }

    const { theme } = useTheme();

    function AddMenuActivate() {
        setMenuVisible(!MenuVisible);
    }

    const AddMenuMaterial = [
        {
            label: "Add Project",
            activate: () => AddObject("AddProject"),
            image: projectImage
        },
        {
            label: "Add Company",
            activate: () => AddObject("AddCompany"),
            image: companyImage
        },
        {
            label: "Add Employee",
            activate: () => AddObject("AddEmployee"),
            image: employeeImage
        }
    ];

    return (
        <div className={`LeftContainer ${theme === "light" ? 'light-background-layer1 light-box-shadow' : 'dark-background-layer1 dark-box-shadow'}`}>
            <div className='TopButtonContainer'> 
                <input type="text" placeholder="Search" className={`SearchTextInput ${theme === "light" ? 'dark-border light-background-layer1' : 'light-border dark-background-layer1'}`}/>
                <button onClick={AddMenuActivate} className='AddButton light-theme-color-orange light-theme-color-orange-hover light-theme-color-orange-active'>+
                    {MenuVisible && <AddMenu material={AddMenuMaterial}/>}
                </button>
            </div>
            <LeftHierarchy />
        </div>
    )
}