import React, {useState, useEffect} from 'react';
import './TableWidget.css';

import { useTheme } from '../ThemeContext';
import { useNavigate } from "react-router-dom";

import getIDFromLink from '../Classes/ParseLink';

import TableTopbar from '../Topbars/TableTopbar';

export default function TableWidget(props) {
    const { theme } = useTheme();

    const navigate = useNavigate();

    const [instanceElements, setInstanceElements] = useState([]);
    const [headers, setHeaders] = useState([]) 

    useEffect(() => {
        const newElements = props.instances && props.instances.entries && props.instances.headers ? (
            Object.entries(props.instances.entries)
              .filter(([key]) => key !== "title" && key !== "headers")
              .map(([key, value], index) => (
                <tr className={`TableEntry ${index === props.instances.entries.length - 1 ? 'RoundedBottom' : ''}`} key={index}>
                  {props.instances.headers.map((header, headerIndex) => (
                    <td key={headerIndex} className="TableValue">
                      {value[header]}
                    </td>
                  ))}
                </tr>
              ))
          ) : null;

        const newHeaders = props.instances && props.instances.headers ? (
            props.instances.headers.map((header, index) => (
                <th key={index} className="TableHeader">
                    {header}
                </th>
            ))
        ) : null;
        

        console.log(newElements)

        setInstanceElements(newElements);
        setHeaders(newHeaders);
    }, [props.instances, theme]);

    return (
        <>
        <TableTopbar title={props.instances ? props.instances.title : ''} addOptions={props.addOptions} addOptionsEnabled={props.addOptionsEnabled}/>
        <table className={`TableWidgetContainer ${theme === "light" ? 'light-background-layer3 light-box-shadow' : 'dark-background-layer3 dark-box-shadow'}`}>
            <thead>
                <tr className={`TableHeaderContainer ${theme === "light" ? 'light-background-layer1' : 'dark-background-layer1'}`}>
                    {headers}
                </tr>
            </thead>
            {/* <div className={`Divider ${theme === "light" ? 'light-background-layer2' : 'dark-background-color-border'}`} /> */}
            <tbody className={`${theme === "light" ? 'light-table-body' : 'dark-table-body'}`}>
                {instanceElements ? instanceElements.length > 0 ? instanceElements : <div className='NoDataTable'>No data available to present.</div> : <div className='NoDataTable'>No data available to present.</div>}
            </tbody>
        </table>
        </>
    )
}