import React from 'react';
import './DownloadSection.css';

import DownloadButton from '../Buttons/DownloadButton';
import downloadImage from '../images/download-black.png';
import installer from '../installers/StructureInSetupV1-0-1.exe';

import { useTheme } from '../ThemeContext';

export default function DownloadSection() {
    const { theme } = useTheme();

    return (
        <div className="MainSection">
            <div className="MainTitle">Structure In Offline Version (Basic Plan).</div>
            <div className="DownloadSubtitleContainer">
                <div className="DownloadSubtitle">Structure In is available for Windows and updates to the software will be made constantly.</div>
            </div>
            <table>
                <tbody>
                    <tr>
                    <th className={theme === "light" ? 'light-background-layer3' : 'dark-background-layer3'}>Features</th>
                    <th className={theme === "light" ? 'light-background-layer3' : 'dark-background-layer3'}>Basic</th>
                    </tr>
                    <tr>
                    <td>Price</td>
                    <td>Free</td>
                    </tr>
                    <tr>
                    <td>Users</td>
                    <td>1</td>
                    </tr>

                    <tr>
                    <td>Employee Management</td>
                    <td>✓</td>
                    </tr>

                    <tr>
                    <td>Project Management</td>
                    <td>✓</td>
                    </tr>

                    <tr>
                    <td>Invoice Management</td>
                    <td>✓</td>
                    </tr>

                    <tr>
                    <td>Invoice Generation</td>
                    <td>✓</td>
                    </tr>

                    <tr>
                    <td>Accounting</td>
                    <td>✓</td>
                    </tr>

                    <tr>
                    <td>Cloud Storage</td>
                    <td></td>
                    </tr>

                    <tr>
                    <td>Multi-User</td>
                    <td></td>
                    </tr>

                    <tr>
                    <td>Structure In Web</td>
                    <td></td>
                    </tr>

                    <tr>
                    <td>Support</td>
                    <td></td>
                    </tr>

                    <tr>
                    <td></td>
                    </tr>
                </tbody>
            </table>
            <div className="DownloadButtonContainer">
                <DownloadButton image={downloadImage} link={installer} download="StructureInSetupV1-0-1.exe" text="Download Version 1.0.1 for Windows" />
            </div>
        </div>
    )
}