import React, {useEffect} from 'react';
import Topbar from '../WebTopbar';
import MainContainer from '../Containers/MainContainer';

import { useTheme } from '../../ThemeContext';

import {FetchAllData, FetchProjects, FetchCompanyEmployees} from '../../API/DataRequests';
import DataManager from '../../Classes/DataManager';

import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../Redux/Actions/userActions';

import { useNavigate } from "react-router-dom";

import AddPopup from '../Containers/AddPopup';

import {addProject, addCompany, addEmployee, addActivity, addInvoice} from '../../API/DataRequests';

import {getIDFromLink} from '../../Classes/ParseLink';

//STATIC DATA
import {addProjectMaterial, addCompanyMaterial, addEmployeeMaterial, addActivityMaterial, addInvoiceMaterial} from '../../StaticData/Material';


export default function WebHome(props) {
    const { theme } = useTheme();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const userData = useSelector((state) => state.userData);

    async function ConfirmAddProject(formData) {
        try {
            formData.name = formData["Project name"];
            delete formData["Project name"];
            const response = await addProject(formData);

            console.log(response)
            
            if (response && response.ProjectID) {
                response.id = response.ProjectID;

                const newUserData = {
                    ...userData.user,
                    companies: {
                        ...userData.user.companies,
                        [getIDFromLink("companies")]: {
                            ...userData.user.companies[getIDFromLink("companies")],
                            projects: {
                                ...userData.user.companies[getIDFromLink("companies")].projects,
                                [response.ProjectID]: response,
                            },
                        },
                    },
                }

                dispatch(setUserData(newUserData));
                console.log(newUserData)
                navigate("/companies/" + getIDFromLink("companies") + "/projects/" + response.ProjectID);
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function ConfirmAddCompany(formData) {
        try {
            formData.name = formData["Company name"];
            delete formData["Company name"];
            const response = await addCompany(formData);

            console.log(response)
            
            if (response && response.CompanyID) {
                response.id = response.CompanyID;

                const newUserData = {
                    ...userData.user,
                    companies: {
                        ...userData.user.companies,
                        [response.CompanyID]: response,
                    },
                }

                dispatch(setUserData(newUserData));
                console.log(newUserData)
                navigate("/companies/" + response.CompanyID);
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function ConfirmAddEmployee(formData) {
        const response = await addEmployee(formData);

        FetchAllData().then((fetchedData) => {
            console.log(fetchedData);
            dispatch(setUserData(fetchedData));
        })
    }

    async function ConfirmAddActivity(formData) {
        const response = await addActivity(formData);

        FetchAllData().then((fetchedData) => {
            console.log(fetchedData);
            dispatch(setUserData(fetchedData));
        })
    }

    async function ConfirmAddInvoice(formData) {
        const response = await addInvoice(formData);

        FetchAllData().then((fetchedData) => {
            console.log(fetchedData);
            dispatch(setUserData(fetchedData));
        })
    }

    async function PopulateRequiredData() {
        var newUserData = {}

        console.log("FETCHING NEW DATA")

        console.log(userData)

        try {
            if (!userData || !userData.user || !userData.user['companies']) {
                console.log("FETCHING COMPLETELY NEW DATA")
                const fetchedData = await FetchAllData();
                console.log(fetchedData);
                newUserData = fetchedData;
            }
        } catch (err) {
            if (err.response.status === 401) {
                console.log("UNAUTHORIZED")
                navigate("/login");
            }
        }

        if (props.instanceType === "projects") {
            console.log("projects")
            //Get projects if not there
            console.log(userData['companies'])
            if (newUserData && Object.keys(newUserData).length === 0) {
                if (userData.user['companies'] && userData.user['companies'][getIDFromLink("companies")] && !userData.user['companies'][getIDFromLink("companies")]['projects']) {
                    console.log("FETCHING NEW PROJECT DATA")
                    const fetchedData = await FetchProjects(getIDFromLink("companies"));
                    console.log(fetchedData);

                    newUserData = {
                        ...userData.user,
                        companies: {
                            ...userData.user.companies,
                            [getIDFromLink("companies")]: {
                                ...userData.user.companies[getIDFromLink("companies")],
                                projects: fetchedData,
                            },
                        },
                    }
                }
            } else if (newUserData) {
                if (newUserData['companies'] && newUserData['companies'][getIDFromLink("companies")] && !newUserData['companies'][getIDFromLink("companies")]['projects']) {
                    console.log("FETCHING NEW PROJECT DATA")
                    const fetchedData = await FetchProjects(getIDFromLink("companies"));
                    console.log(fetchedData);

                    newUserData['companies'][getIDFromLink("companies")]['projects'] = fetchedData;
                }
            }

            //Get employees
            if (newUserData && Object.keys(newUserData).length === 0) {
                if (userData.user['companies'] && userData.user['companies'][getIDFromLink("companies")] && !userData.user['companies'][getIDFromLink("companies")]['employees']) {
                    console.log("FETCHING NEW EMPLOYEE DATA")
                    const fetchedData = await FetchCompanyEmployees(getIDFromLink("companies"));
                    console.log(fetchedData);

                    newUserData = {
                        ...userData.user,
                        companies: {
                            ...userData.user.companies,
                            [getIDFromLink("companies")]: {
                                ...userData.user.companies[getIDFromLink("companies")],
                                employees: fetchedData,
                            },
                        },
                    }
                }
            } else if(newUserData) {
                if (newUserData['companies'] && newUserData['companies'][getIDFromLink("companies")] && !newUserData['companies'][getIDFromLink("companies")]['employees']) {
                    console.log("FETCHING NEW EMPLOYEE DATA")
                    const fetchedData = await FetchCompanyEmployees(getIDFromLink("companies"));
                    console.log(fetchedData);
                    newUserData['companies'][getIDFromLink("companies")]['employees'] = fetchedData;
                }
            }

        }

        if (newUserData && Object.keys(newUserData).length > 0) {
            console.log(newUserData)
            dispatch(setUserData(newUserData));
        }
    }

    useEffect(() => {
        PopulateRequiredData();
    }, [props.instanceType]);

    return (
        <>
            <Topbar domain="/web" />
            <MainContainer theme={theme} instanceType={props.instanceType} />
            <AddPopup material={addCompanyMaterial} id={"AddCompany"} title="Add Company" ConfirmAddPopup={ConfirmAddCompany} />
            <AddPopup material={addProjectMaterial} id={"AddProject"} title="Add Project" ConfirmAddPopup={ConfirmAddProject} />
            <AddPopup material={addEmployeeMaterial} id={"AddEmployee"} title="Add Employee" ConfirmAddPopup={ConfirmAddEmployee} />
            <AddPopup material={addActivityMaterial(userData['user'])} id={"AddActivity"} title="Add Activity" ConfirmAddPopup={ConfirmAddActivity} />
            <AddPopup material={addInvoiceMaterial(userData['user'])} id={"CreateInvoice"} title="Create Invoice" ConfirmAddPopup={ConfirmAddInvoice} />
        </>
    )
}