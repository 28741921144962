import React from 'react';
import './Download.css';
import DownloadSection from '../Containers/DownloadSection';
import Topbar from '../Topbar';

export default function Download() {
    return (
        <>
            <Topbar domain="/" />
            <DownloadSection />
        </>
    )
}