import React, {useState} from 'react';
import './MainSection.css';
import laptopImage from '../images/laptop2.png';
import logo from '../images/logo_square.png';
import employeeImage from '../images/profileIcon.png';
import projectImage from '../images/projectsImage.png';
import employeeFeatureImage from '../images/featureImages/employees.png';
import projectFeatureImage from '../images/featureImages/projects.png';

import HexOptions from '../InputWidgets/HexOptions';

import { useTheme } from '../ThemeContext';

const features = [
    {
        label: "Employees",
        image: employeeImage,
    },
    {
        label: "Projects",
        image: projectImage,
    },
]

const FeatureDescriptions = {
    "Employees": {
        title: "Employee Management",
        text: "Manage your employees with ease. Invite employees to collaborate on all your companies projects or select projects. Track employee timesheets, expenses, and activities.",
        image: employeeFeatureImage,
    },
    "Projects": {
        title: "Project Management",
        text: "Manage your projects with ease. Track everything that is important about your project. Automatically generate invoices and track expenses. Manage your project team and track project progress.",
        image: projectFeatureImage,
    }

}

export default function MainSection() {
    const { theme } = useTheme();

    const [selectedFeature, setSelectedFeature] = useState(features[0].label);

    function OptionSelected(id, option) {
        setSelectedFeature(option);
    }

    return (
        <div className={`MainSection ${theme === "light" ? 'light-background-layer1-gradient' : 'dark-background-layer1-gradient'}`}>
            <div className="MainTitle"><img src={logo} alt="St"></img>ructure In</div>
            <div className="laptopContainer">
                <div className={`laptopImageContainer ${theme === "light" ? 'light-background-layer3' : 'dark-background-layer3'}`}> 
                    <img src={laptopImage} alt="laptop" className="laptopImage"></img>
                </div>
            </div>
            <div className='MainSubtitleContainer'>
            <div className="MainSubtitle">The only system your small business needs</div>
            </div>

            <div className="FeaturesContainer">
                <HexOptions options={features} title="Discover what Structure In can do for you." OptionSelected={OptionSelected}/>
                <div className="FeatureDescription"> 
                    <div className="FeatureDescriptionTitle">{FeatureDescriptions[selectedFeature].title}</div>
                    <div className="FeatureDescriptionText">{FeatureDescriptions[selectedFeature].text}</div>
                    <div className="FeatureImageContainer">
                        <img src={FeatureDescriptions[selectedFeature].image} alt="employee" className="FeatureImage"></img>
                    </div>
                </div>
            </div>
        </div>
    )
}