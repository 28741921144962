export function formatDecimals(inputValue) {
    if (!inputValue) {
        return '0.00';
    }

    //if inputValue is a number convert to string
    if (typeof inputValue === 'number') {
        inputValue = inputValue.toString();
    }

    // Remove any non-numeric or non-decimal characters
    inputValue = inputValue.replace(/[^0-9.]/g, '');

    // Ensure only one decimal point
    const decimalCount = (inputValue.match(/\./g) || []).length;
    if (decimalCount > 1) {
        inputValue = inputValue.slice(0, inputValue.lastIndexOf('.'));
    }

    // Format to two decimal places
    var parts = null;

    if (inputValue.includes('.')) {
        parts = inputValue.split('.');
        parts[1] = parts[1].slice(0, 2); // Only take two decimal places
        inputValue = parts.join('.');
    } else {
        // Add .00 if there is no decimal point
        inputValue += '.00';
        parts = inputValue.split('.');
        parts[1] = parts[1].slice(0, 2); // Only take two decimal places
        inputValue = parts.join('.');
    }

    // Remove leading zeros in the integer part
    if (inputValue.includes('.')) {
        let integerPart = inputValue.split('.')[0];
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas
        const decimalPart = inputValue.includes('.') ? '.' + parts[1] : '';
        inputValue = integerPart + decimalPart;
    }

    return inputValue;
}