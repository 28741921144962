import React, { useEffect, useState } from 'react';
import './ListSelector.css';

import { useTheme } from '../ThemeContext';

export default function ListSelector(props) {
    const { theme } = useTheme();

    const [selectedOption, setSelectedOption] = useState([]);

    const allOptions = props.items.map((data) => (
        <div className={`SelectionItemContainer ${theme === 'light' ? 'light-background-layer2' : 'dark-background-layer2'}`}>
        <input type='checkbox'
            key={data.description}
            value={data.description}
            className={`SelectionItem ${
                theme === 'light'
                    ? 'light-background-layer2'
                    : 'dark-background-layer2'
            }`}
        />
        {data.description}
        </div>
    ));

    function handleDropdownChange(event) {
        const selectedValue = event.target.value;
        console.log(selectedValue)
        const newSelection = [...selectedOption];
    
        if (event.target.checked) {
            const selectedItem = props.items.find(item => item.description === selectedValue);
    
            if (!newSelection.some(item => item.description === selectedValue) && selectedItem) {
                newSelection.push({
                    description: selectedValue,
                    quantity: selectedItem.quantity,
                    price: selectedItem.price
                });
            }
        } else {
            const indexToRemove = newSelection.findIndex(item => item.description === selectedValue);
            if (indexToRemove !== -1) {
                newSelection.splice(indexToRemove, 1);
            }
        }

        console.log(newSelection)
    
        setSelectedOption(newSelection);
        props.ListSelected(newSelection);
    }

    return (
        <>
            <div
                className={`DropdownTitle ${
                    theme === 'light'
                        ? 'light-background-layer2'
                        : 'dark-background-layer2'
                }`}
            >
                {props.title}
            </div>
            <div
                value={selectedOption}
                onChange={handleDropdownChange}
                className={`ListSelectorContainer ${
                    theme === 'light'
                        ? 'light-background-layer2 dark-border'
                        : 'dark-background-layer2 light-border'
                }`}
            >
                {allOptions}
            </div>
        </>
    );
}
