import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

export function ThemeProvider({ children }) {
  // Retrieve the theme preference from local storage (or use a default)
  const [theme, setTheme] = useState(() => {
    const storedTheme = localStorage.getItem('theme');
    return storedTheme || 'light';
  });

  const toggleTheme = () => {
    // Toggle the theme between 'light' and 'dark'
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    // Store the new theme preference in local storage
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    // Update the class on the <html> element for global theming
    document.documentElement.classList.remove('light', 'dark');
    document.documentElement.classList.add(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}
