import React from "react"
import "./SubFeature.css"

import { useTheme } from '../ThemeContext';

export default function SubFeature(props) {
    const { theme } = useTheme();

    return (
        <div className={`SubFeature ${theme === "light" ? 'light-background-layer1' : 'dark-background-layer1'}`}>
            <div className={`SubFeatureTitle ${theme === "light" ? 'light-background-layer1' : 'dark-background-layer1'}`} id={props.id}>{props.title}</div>
            <div className="SubFeatureDescription" >{props.paragraphs}</div>
            <div className="SubFeatureImageContainer">
                <img className="SubFeatureImage" alt="" src ={props.image}></img>
            </div>
        </div>
    )
}