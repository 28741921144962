import React, { createContext, useContext, useState, useEffect } from 'react';

const BlurContext = createContext();

export function useBlur() {
  return useContext(BlurContext);
}

export function BlurProvider({ children }) {
  const [blur, setBlur] = useState(() => {
    return false;
  });

  const toggleBlur = () => {
    const newBlur = !blur
    setBlur(newBlur);
  };

  return (
    <BlurContext.Provider value={{ blur, toggleBlur }}>
      {children}
    </BlurContext.Provider>
  );
}
