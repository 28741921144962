import React, { useState, useEffect } from 'react';
import './HierarchyItem.css';

import { useTheme } from '../../ThemeContext';

export default function HierarchyItem(props) {
  const { theme } = useTheme();

  const [HierarchyChildren, setHierarchyChildren] = useState(null);
  const [childrenVisible, setChildrenVisible] = useState(false);

  const selected = props.isSelected;

  useEffect(() => {
    const newElements = props.children
      ? Object.entries(props.children).map(([key,data]) => (
          <HierarchyItem
            indent="10px"
            key={key}
            id={key}
            title={data.name}
            isSelected={props.isSelected}
            onSelect={props.onSelect}
            type={"projects"}
            parent={props.id}
            image={props.childImage}
          />
        ))
      : null;

    setHierarchyChildren(newElements);
  }, [props.children, props.isSelected, props.onSelect]);

  function showChildren() {
    setChildrenVisible(!childrenVisible);
  }

  function selectItem() {
    props.onSelect(props.id, props.type, props.parent);
  }

  return (
    <>
      <button
        onClick={selectItem}
        style={{ textIndent: props.indent }}
        className={`HierarchyItem ${
          theme === 'light'
            ? selected
              ? 'light-primary-color-hover-background'
              : ''
            : selected
            ? 'dark-primary-color-hover-background'
            : ''
        } ${theme === 'light'
          ? 'light-primary-color-active light-primary-color-hover light-background-layer1'
          : 'dark-primary-color-active dark-primary-color-hover dark-background-layer1'}`}
      >
        {HierarchyChildren && HierarchyChildren.length > 0 && (
          <div
            className={`ShowChildren ${childrenVisible ? 'ShowChildrenEnabled' : ''}`}
            onClick={showChildren}
          >
            {'>'}
          </div>
        )}
        <img className={`HierarchyIcon ${theme === 'light' ? '' : 'dark-image'}`} src={props.image}></img>
        <div className='HierarchyItemTitle'>{props.title}</div>
      </button>
      {childrenVisible && HierarchyChildren}
    </>
  );
}
