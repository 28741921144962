import React, {useState, useEffect} from 'react';
import './InvoicePdfInput.css';

import { useTheme } from '../ThemeContext';

import {getIDFromLink} from '../Classes/ParseLink';
import TelephoneInput from './TelephoneInput';
import TextInput from './TextInput';
import DateInput from './DateInput';
import DescriptionInput from './DescriptionInput';

import { formatDecimals } from '../formatMethods/DecimalValue';


export default function InvoicePdfInput(props) {
    const { theme } = useTheme();

    const [selectedOptions, setSelectedOptions] = useState({});

    const [invoiceItems, setInvoiceItems] = useState([]);
    const [invoiceTotal, setInvoiceTotal] = useState('0.00');

    useEffect(() => {
        var invoioceItemsArray = [];
        
        if (!props.items) {setInvoiceItems(invoioceItemsArray); return;}

        var total = 0;

        props.items.forEach((item) => {
            console.log(total)
            total += item.quantity * item.unitPrice;
            console.log(total)

            invoioceItemsArray.push(
                <tr className="invoice-item">
                    <td className="invoice-cell">{item.description}</td>
                    <td className="invoice-cell">{formatDecimals(item.quantity)}</td>
                    <td className="invoice-cell">${formatDecimals(item.unitPrice)}</td>
                    <td className="invoice-cell">${formatDecimals(item.quantity * item.unitPrice)}</td>
                </tr>
            );
        });

        setInvoiceItems(invoioceItemsArray);

        console.log(total)
        setInvoiceTotal(formatDecimals(total));

    }, [props.items])

    function closePopup() {
        const elem = document.getElementById(props.id);
        elem.classList.remove("show-popup");
    }

    function ConfirmCreateInvoice() {
        const formData = {};
      
        props.material.forEach((data) => {
          if (data.type === "options" || data.type === "dropdownlist") {
            formData[data.label] = selectedOptions[data.label];
          } else {
            formData[data.label] = document.getElementById(data.label).value;
          }
        });

        formData["companyID"] = getIDFromLink("companies");
        formData["projectID"] = getIDFromLink("projects");
      
        props.ConfirmAddPopup(formData);
      
        closePopup();
    }

    return (
    <div className='invoice-container'>
        <div>
        <img alt="img" src="[Replace with image base64]"/>
        <h1 className='invoice-title'>INVOICE</h1>
        </div>

        <table>
            <tr className='invoice-details-container'>
                <td className="details-left">
                    <div>
                        <p><b>Phone: </b><TelephoneInput default={props.data ? props.data.phone : null} theme='light'/></p>
                        <p><b>Address: </b><TextInput default = {props.data ? props.data.address : null} theme='light' /></p>
                        <p><b>Email: </b><TextInput default={props.data ? props.data.email : null} theme='light'/></p>
                    </div>
                </td>
                <td className="details-right">
                    <div>
                        <p><b>Invoice #: </b><TextInput default={props.data ? props.data.invoiceNumber : null} theme='light'/></p>
                        <p><b>Date: </b><DateInput default={props.data ? props.data.date : null} theme='light'/></p>
                        <p><b>Due Date: </b><DateInput default={props.data ? props.data.due : null} theme='light'/></p>
                    </div>
                </td>
            </tr>
        </table>

        <table>
            <tr className="invoice-box-container">
                <td className="invoice-box">
                    <h3>Bill To:</h3>
                    <p><DescriptionInput default={props.data ? props.data.billTo : null} theme='light'/></p>
                </td>
                <td className="invoice-box">
                    <h3>Project Address:</h3>
                    <p><DescriptionInput default={props.data ? props.data.billTo : null} theme='light'/></p>
                </td>
            </tr>
        </table>

        <table className="invoice">
            <thead>
                <tr className="invoice-header">
                    <th className="invoice-cell">Description</th>
                    <th className="invoice-cell">Quantity</th>
                    <th className="invoice-cell">Unit Price</th>
                    <th className="invoice-cell">Subtotal</th>
                </tr>
            </thead>
            <tbody>
                {invoiceItems}
            </tbody>
            <tr class="invoice-total">
                <td className="invoice-cell" colspan="3">Total</td>
                <td className="invoice-cell"> {'$' + invoiceTotal} </td>
            </tr>
        </table>
    </div>
    ); 
}