import React, {useEffect} from 'react';
import './AddMenu.css';

import { useTheme } from '../../ThemeContext';
import { useBlur } from '../../BlurContext';


export default function AddMenu(props) {
    const { theme } = useTheme();

    const { blur, toggleBlur } = useBlur();

    const MenuOptions = props.material.map((data) => (
        <button key={data.label} onClick={() => {toggleBlur(); data.activate()}} className={`AddMenuButton ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer1' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer1'}`}>
            <img src={data.image} className={`MenuButtonImage ${theme === "light" ? '' : 'dark-image'}`} alt='m' />
            {data.label}
        </button>
    ));

    function closePopup() {
        const elem = document.getElementById(props.id);
        elem.classList.remove("show-popup");
    }

    useEffect(() => {
        setTimeout(() => {
            const elem = document.getElementById("AddMenu");
            elem.classList.add("show-addMenu");
        },);
    }, [])

    return (
        <div id="AddMenu" style={props.width ? {width: props.width} : {width: '250px'}} className={`AddMenuContainer ${theme === "light" ? 'light-background-layer1 light-box-shadow' : 'dark-background-layer1 dark-box-shadow'}`}>
            {MenuOptions}
        </div>
    )
}