import React, {useState, useEffect} from 'react';

import './EmployeeIcon.css';
import InfoDisplay from './InfoDisplay';

import { useNavigate } from "react-router-dom";

import {getIDFromLink} from '../Classes/ParseLink';

export default function EmployeeIcon(props) {
    const [displayInfo, setDisplayInfo] = useState(false);

    const navigate = useNavigate();

    function visitEmployeePage() {
        if (!props.employeeId) {return}

        navigate('/companies/' + getIDFromLink('companies') + '/employees/' + props.employeeId);
    }

    function handleMouseEnter() {
        setDisplayInfo(true);
    }

    function handleMouseLeave() {
        setDisplayInfo(false);
    }

    return (
        <div className={`TableProfile`} onClick={visitEmployeePage} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {props.initials}
            <InfoDisplay info={props.fullName} displayed={displayInfo}/>
        </div>
    )
}