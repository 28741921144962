import './PlansSection.css'
import React from 'react';
import DownloadButton from '../Buttons/DownloadButton';
import arrowImage from '../images/arrow.svg';

import { useTheme } from '../ThemeContext';

export default function PlansSection() {
    const { theme } = useTheme();

    return (
        <div className={`PlanSection ${theme === "light" ? 'light-background-layer1' : 'dark-background-layer1'}`}>
            <div className="PlanSectionTitle">Choose the right plan for you.</div>
            <div className="PlanSectionSubtitle">Start your 7-day free trial today.</div>

            <div className="PlanSectionPlans">
                <div className={`PlanSectionPlan ${theme === "light" ? 'light-background-layer3 light-box-shadow' : 'dark-background-layer3 dark-box-shadow'}`}>
                    <div className="PlanSectionPlanTitle">Basic.</div>
                    <div className='PlanSectionPriceGroup'>
                        <div className="PlanSectionPlanPrice">$10</div>
                        <div className="PlanSectionPlanPriceSubtitle">
                            <div className="PlanSectionPlanPriceSubtitleText">employee</div>
                            <div className="PlanSectionPlanPriceSubtitleText">/month</div>
                        </div>
                    </div>

                    <div className={`PlanSectionDivider ${theme === "light" ? 'dark-border-background' : 'light-border-background'}`}> </div>

                    <div className="PlanSectionPlanFeatures"> 
                        <div className="PlanSectionPlanFeature">{'>'}One Company.</div>
                        <div className="PlanSectionPlanFeature">{'>'}Unlimited projects.</div>
                        <div className="PlanSectionPlanFeature">{'>'}Unlimited activities.</div>
                        <div className="PlanSectionPlanFeature">{'>'}Unlimited transactions.</div>
                        <div className="PlanSectionPlanFeature">{'>'}Unlimited invoices.</div>
                        <div className="PlanSectionPlanFeature">{'>'}10 GB file storage.</div>                    
                    </div>

                    <DownloadButton text="Try free for 7 days" link="/signup" width="300px" image={arrowImage}/>
                </div>

                <div className={`PlanSectionPlan ${theme === "light" ? 'light-background-layer3 light-box-shadow' : 'dark-background-layer3 dark-box-shadow'}`}>
                    <div className="PlanSectionPlanTitle">Pro.</div>
                    <div className='PlanSectionPriceGroup'>
                        <div className="PlanSectionPlanPrice">$20</div>
                        <div className="PlanSectionPlanPriceSubtitle">
                            <div className="PlanSectionPlanPriceSubtitleText">employee</div>
                            <div className="PlanSectionPlanPriceSubtitleText">/month</div>
                        </div>
                    </div>

                    <div className={`PlanSectionDivider ${theme === "light" ? 'dark-border-background' : 'light-border-background'}`}> </div>

                    <div className="PlanSectionPlanFeatures"> 
                        <div className="PlanSectionPlanFeature">{'>'}Two Companies.</div>
                        <div className="PlanSectionPlanFeature">{'>'}Unlimited projects.</div>
                        <div className="PlanSectionPlanFeature">{'>'}Unlimited activities.</div>
                        <div className="PlanSectionPlanFeature">{'>'}Unlimited transactions.</div>
                        <div className="PlanSectionPlanFeature">{'>'}Unlimited invoices.</div>
                        <div className="PlanSectionPlanFeature">{'>'}Payroll & payroll taxes.</div>
                        <div className="PlanSectionPlanFeature">{'>'}100 GB file storage.</div>                    
                    </div>

                    <DownloadButton text="Try free for 7 days" link="/signup" width="300px" image={arrowImage}/>
                </div>
            </div>
        </div>
    )
}