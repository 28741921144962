import React, { useEffect, useState } from 'react';
import './Dropdown.css';

import { useTheme } from '../ThemeContext';

export default function Dropdown(props) {
    const { theme } = useTheme();

    const [selectedOption, setSelectedOption] = useState(
        props.options.length > 0 ? props.options[0].label : null
    );

    const allOptions = props.options.map((data) => (
        <option
            key={data.label}
            value={data.label}
            className={`DropdownOption ${
                theme === 'light'
                    ? 'light-background-layer2'
                    : 'dark-background-layer2'
            }`}
        >
            {data.label}
        </option>
    ));

    function handleDropdownChange(event) {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        props.DropdownSelected(props.id, selectedValue);
    }

    useEffect(() => {
        console.log(selectedOption)
        if (selectedOption === null) {
            if (props.options.length > 0) {
                console.log(props.options)
                setSelectedOption(props.options[0].label);
                props.DropdownSelected(props.id, props.options[0].label);
                console.log(props.options[0].label)
            }
        }
    }, [props.options]);

    return (
        <>
            <div
                className={`DropdownTitle ${
                    theme === 'light'
                        ? 'light-background-layer2'
                        : 'dark-background-layer2'
                }`}
            >
                {props.title}
            </div>
            <select
                value={selectedOption}
                onChange={handleDropdownChange}
                className={`DropdownContainer ${
                    theme === 'light'
                        ? 'light-background-layer2 dark-border'
                        : 'dark-background-layer2 light-border'
                }`}
            >
                {allOptions}
            </select>
        </>
    );
}
