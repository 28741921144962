import React from 'react';

export default function TextInput(props) {
    return (
        <> 
        <div key={props.label} className={`PopupLabel ${props.theme === "light" ? 'light-background-layer2' : 'dark-background-layer2'}`}> {props.label} </div> 
        {!props.hideText && <input type="text" id={props.id} className={`PopupTextInput ${props.theme === "light" ? 'dark-border light-background-layer2' : 'light-border dark-background-layer2'}`}/>}
        {props.hideText && <input type="password" id={props.id} className={`PopupTextInput ${props.theme === "light" ? 'dark-border light-background-layer2' : 'light-border dark-background-layer2'}`}/>}
        </>
    );
}