export function getIDFromLink(IDType) {
    const link = window.location.href;
    const linkArray = link.split('/');
    const index = linkArray.indexOf(IDType);
    
    let companyID = null;
    if (index !== -1 && index + 1 < linkArray.length) {
        companyID = linkArray[index + 1];
    }

    return companyID;
}

export function getEmployeeIDFromDropdown(dropdownText) {
    if (!dropdownText) return null;

    const idRegex = /\(([^)]+)\)$/;
    const match = dropdownText.match(idRegex);
    const extractedId = match ? match[1] : null;

    return extractedId;
}