import React, { useEffect, useState } from 'react';
import './LeftLinkContainer.css';

import { useTheme } from '../ThemeContext';

export default function LeftLinkContainer({ onFeatureClick }) {
    const { theme } = useTheme();

    useEffect(() => {
        window.addEventListener('scroll', stopAtContact);

        return () => {
        window.removeEventListener('scroll', stopAtContact);
        };

    }, []);

    useEffect(() => {
        window.addEventListener('resize', resizeContainer);

        return () => {
        window.removeEventListener('resize', resizeContainer);
        };

    }, []);

    const [leftContainerClosed, setLeftContainerClosed] = useState(window.innerHeight < 800 ? true : false);

    useEffect(() => {
        CloseLeftContainer();
    }, [leftContainerClosed]);    

    function stopAtContact() {
        var leftNavbar = document.querySelector('.LeftLinkContainer');

        if (leftNavbar == null) {
            leftNavbar = document.querySelector('.LeftLinkContainerStop');
        }

        var contactSection = document.querySelector('#ContactContainer');
        var navbarHeight = leftNavbar.offsetHeight;
        var contactSectionPosition = contactSection.offsetTop - navbarHeight;
        var leftNavbarTitle = document.querySelector('.LeftLinkTitle');

        // Check if the scroll position is above the contact section
        if (window.scrollY < contactSectionPosition) {
            leftNavbar.classList.add('LeftLinkContainer');
            leftNavbar.classList.remove('LeftLinkContainerStop');
            leftNavbar.style.top = '45px';
        } else {
            // When the scroll position reaches the contact section, stop fixing the navbar
            leftNavbar.classList.remove('LeftLinkContainer');
            leftNavbar.classList.add('LeftLinkContainerStop');
            leftNavbar.style.top = contactSectionPosition + 'px';
            if (contactSectionPosition < 50) {
                leftNavbarTitle.style.paddingTop = '70px';
            } else {
                leftNavbarTitle.style.paddingTop = '30px';
            }
            console.log("Over")
        }
    }

    const handleFeatureClick = (feature) => {
        onFeatureClick(feature); // Pass the clicked feature back to the parent component
    };

    function TransitionOnHover() {
        var leftNavbar = document.querySelector('.LeftLinkContainer');
        if (leftNavbar == null) {
            leftNavbar = document.querySelector('.LeftLinkContainerStop');
        }

        leftNavbar.classList.add('HoverTransition');

        var CloseLeftContainer = document.querySelector('.CloseLeftContainer');
        CloseLeftContainer.classList.add('HoverTransition');
    }

    function removeHover() {
        var leftNavbar = document.querySelector('.LeftLinkContainer');
        if (leftNavbar == null) {
            leftNavbar = document.querySelector('.LeftLinkContainerStop');
        }

        leftNavbar.classList.remove('HoverTransition');

        var CloseLeftContainer = document.querySelector('.CloseLeftContainer');
        CloseLeftContainer.classList.remove('HoverTransition');
    }

    function LeftContainerPressed() {
        setLeftContainerClosed(!leftContainerClosed)
    }

    function CloseLeftContainer() {
        var leftNavbar = document.querySelector('.LeftLinkContainer');
        if (leftNavbar == null) {
            leftNavbar = document.querySelector('.LeftLinkContainerStop');
        }

        if (leftNavbar == null) {return}

        var featureContainer = document.querySelector('.FeatureContainer');


        var childClassNames = ['LeftLinkItem', 'LeftLinkSubItem', 'LeftLinkTitle'];
        var CLoseButton = document.querySelector('.CloseLeftContainer');

        TransitionOnHover();

        if (leftContainerClosed) {
            featureContainer.style.marginLeft = '100px';

            // Hide child elements of leftNavbar
            // Hide elements with each class name
            for (const className of childClassNames) {
                var childElements = leftNavbar.querySelectorAll('.' + className);
                for (const element of childElements) {
                    element.style.display = 'none';
                }
            }

            leftNavbar.style.width = '100px'

            CLoseButton.style.transform = 'rotate(180deg)';
        } else {
            if (window.innerWidth < 800) {
                featureContainer.style.marginLeft = '15%';
                leftNavbar.style.width = '80%'
            } else if (window.innerWidth < 1800) {
                featureContainer.style.marginLeft = '30%';
                leftNavbar.style.width = '30%'
            } else {
                featureContainer.style.marginLeft = '15%';
                leftNavbar.style.width = '15%'
            }

            // Show child elements of leftNavbar
            // Show elements with each class name
            for (const className of childClassNames) {
                childElements = leftNavbar.querySelectorAll('.' + className);
                for (const element of childElements) {
                    element.style.display = 'block';
                }
            }

            CLoseButton.style.transform = 'rotate(0deg)';
        }
    }

    function resizeContainer() {
        var leftNavbar = document.querySelector('.LeftLinkContainer');
        if (leftNavbar == null) {
            leftNavbar = document.querySelector('.LeftLinkContainerStop');
        }

        var featureContainer = document.querySelector('.FeatureContainer');

        if (window.innerWidth < 800) {
            if (!leftContainerClosed) {
                featureContainer.style.marginLeft = '15%';
                leftNavbar.style.width = '80%'
            }
        } else if (window.innerWidth < 1800) {
            if (!leftContainerClosed) {
                featureContainer.style.marginLeft = '30%';
                leftNavbar.style.width = '30%'
            }
        } else {
            featureContainer.style.marginLeft = '15%';
            leftNavbar.style.width = '15%'
        }
    }

    return (
        // <div className="LeftLinkContainer" onMouseEnter={TransitionOnHover} onMouseLeave={TransitionOnHover} onTransitionEnd={removeHover}>
        <div className={`LeftLinkContainer ${theme === "light" ? 'light-background-layer3' : 'dark-background-layer3'}`} onTransitionEnd={removeHover}>
            <div className={`LeftLinkTitle ${theme === "light" ? 'light-background-layer3' : 'dark-background-layer3'}`}>Features List</div>
            <button className={`LeftLinkItem ${theme === "light" ? 'light-background-layer3 light-primary-color-hover' : 'dark-background-layer3 dark-primary-color-hover'}`} onClick={() => handleFeatureClick('Company')}>
            Company Management
            </button>
            <a href="#addcompany" className={`LeftLinkSubItem ${theme === "light" ? 'light-background-layer3 light-primary-color-hover' : 'dark-background-layer3 dark-primary-color-hover'}`} onClick={() => handleFeatureClick('Company')}>
            Adding a Company
            </a>
            <button className={`LeftLinkItem ${theme === "light" ? 'light-background-layer3 light-primary-color-hover' : 'dark-background-layer3 dark-primary-color-hover'}`} onClick={() => handleFeatureClick('Projects')}>
            Project Management
            </button>
            <a href="#addproject" className={`LeftLinkSubItem ${theme === "light" ? 'light-background-layer3 light-primary-color-hover' : 'dark-background-layer3 dark-primary-color-hover'}`} onClick={() => handleFeatureClick('Projects')}>
            Adding a Project
            </a>
            <a href="#addactivity" className={`LeftLinkSubItem ${theme === "light" ? 'light-background-layer3 light-primary-color-hover' : 'dark-background-layer3 dark-primary-color-hover'}`} onClick={() => handleFeatureClick('Projects')}>
            Adding an Activity
            </a>
            <a href="#generateinvoice" className={`LeftLinkSubItem ${theme === "light" ? 'light-background-layer3 light-primary-color-hover' : 'dark-background-layer3 dark-primary-color-hover'}`} onClick={() => handleFeatureClick('Projects')}>
            Generating an Invoice
            </a>
            <a href="#viewinvoice" className={`LeftLinkSubItem ${theme === "light" ? 'light-background-layer3 light-primary-color-hover' : 'dark-background-layer3 dark-primary-color-hover'}`} onClick={() => handleFeatureClick('Projects')}>
            Viewing an Invoice
            </a>
            <a href="#linkagreement" className={`LeftLinkSubItem ${theme === "light" ? 'light-background-layer3 light-primary-color-hover' : 'dark-background-layer3 dark-primary-color-hover'}`} onClick={() => handleFeatureClick('Projects')}>
            Linking an Agreement
            </a>
            <button className={`LeftLinkItem ${theme === "light" ? 'light-background-layer3 light-primary-color-hover' : 'dark-background-layer3 dark-primary-color-hover'}`} onClick={() => handleFeatureClick('Employees')}>
            Employee Management
            </button>
            <a href="#addemployee" className={`LeftLinkSubItem ${theme === "light" ? 'light-background-layer3 light-primary-color-hover' : 'dark-background-layer3 dark-primary-color-hover'}`} onClick={() => handleFeatureClick('Employees')}>
            Adding an Employee
            </a>
            <a href="#editemployee" className={`LeftLinkSubItem ${theme === "light" ? 'light-background-layer3 light-primary-color-hover' : 'dark-background-layer3 dark-primary-color-hover'}`} onClick={() => handleFeatureClick('Employees')}>
            Editing an Employee
            </a>
            <div className='CloseLeft'>
                <button className="CloseLeftContainer" onClick={LeftContainerPressed}> &lt; </button>
            </div>
        </div>
    );
}