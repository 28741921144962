import logo from '../images/logo_square.png';

import './Contact.css';
import { useTheme } from '../ThemeContext';

export default function Contact(props) {
    const { theme } = useTheme();

    return (
        <div id='ContactContainer' className={`ContactContainer ${theme === "light" ? 'light-background-layer2' : 'dark-background-layer2'}`}> 
            <img src={logo} alt="St"></img>
            <div className="ContactParagraph">
                © 2023 Structure In. All rights reserved.
            </div>
        </div>
    )
}