import React, {useEffect, useState} from 'react';
import './TelephoneInput.css';

import { useTheme } from '../ThemeContext';

export default function TelephoneInput(props) {

    const [phoneNumber, setPhoneNumber] = useState(props.default ? props.default : '');
    const [id, setId] = useState('');

    useEffect(() => {
        if (props.id) {
            setId(props.id);
            console.log(props.id);
        } else {
            setId(props.label);
            console.log(props.id);
        }
    }, [props.id, props.label]);

    // Function to format a 10-digit phone number
    function formatPhoneNumber(value) {
      const match = value.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }
      return value;
    }

    function handleNumbers(event) {
        var inputValue = event.target.value;
        
        if (!inputValue) {
            setPhoneNumber('');
            return;
        }

        // Remove any non-numeric or non-decimal characters
        inputValue = inputValue.replace(/\D/g, '');

        const formattedNumber = formatPhoneNumber(inputValue);

        setPhoneNumber(formattedNumber);
    }

    function displayNumber(event) {
        var inputValue = event.target.value;

        // Remove any non-numeric or non-decimal characters
        inputValue = inputValue.replace(/[^0-9.]/g, '');
        
        setPhoneNumber(inputValue);
    }

    return (
        <>
            <div key={props.label} className={`PopupLabel ${props.theme === "light" ? 'light-background-layer2' : 'dark-background-layer2'}`}>{props.label}</div>
            <input
                type="text"
                onInput={displayNumber}
                onBlur={handleNumbers}
                id={id}
                className={`PopupTextInput ${props.theme === "light" ? 'dark-border light-background-layer2' : 'light-border dark-background-layer2'}`}
                value={phoneNumber}
            />
        </>
    );
}