import React, {useState, useEffect} from 'react';
import './WorkspaceContainer.css';

import { useTheme } from '../ThemeContext';
import { useNavigate } from "react-router-dom";

import {getIDFromLink} from '../Classes/ParseLink';
import TableTopbar from '../Topbars/TableTopbar';

export default function WorkspaceContainer(props) {
    const { theme } = useTheme();

    const navigate = useNavigate();

    function openInstance(id) {
        if (!id || !props.instanceType) {return;}

        if (props.instanceType === "projects") {
            navigate('/companies/' + getIDFromLink("companies") + '/projects/' + id);
        } else if (props.instanceType === "companies") {
            navigate('/companies/' + id);
            console.log("NAVIGATED TO: " + '/companies/' + id)
        }
    }

    const [instanceElements, setInstanceElements] = useState([]);

    useEffect(() => {
        const newElements = props.instances ? Object.values(props.instances)?.map((data) => (
            <button key={data.id} id={data.id} onClick={() => openInstance(data.id)} className={`InstanceHolder ${theme === "light" ? 'light-primary-color-hover-border light-primary-color-active-border light-background-layer3 light-border' : 'dark-primary-color-hover-border dark-primary-color-active-border dark-background-layer3 dark-border-light'}`}>
                <img src={props.image} className={`InstanceImage ${theme === "light" ? '' : ''}`} alt={data.Name} />
                <div className='InstanceName'> 
                    {data.Name}
                </div>
            </button>
        )) : null;

        console.log(newElements)

        setInstanceElements(newElements);
    }, [props.instances, theme, props.image, props.instanceType]);

    return (
        <>
        <TableTopbar title={props.title} addOptions={props.addOptions} addOptionsEnabled={props.addOptionsEnabled}/>
        <div className={`WorkspaceContainer ${theme === "light" ? 'light-background-layer3' : 'dark-background-layer3'}`}>
            {instanceElements}
        </div>
        </>
    )
}