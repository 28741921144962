import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './Pages/Home';
import Download from './Pages/Download';
import Features from './Pages/Features';
import WebHome from './StructureInWeb/Pages/Home';
import Login from './Pages/Login/Login';

export default function Main(props) {

  return (
    <Routes> 
        <Route exact path='/' element={<Home/>}></Route>
        <Route exact path='/download' element={<Download/>}></Route>
        <Route exact path='/learn' element={<Features/>}></Route>
        <Route exact path='/login' element={<Login login={true} />}></Route>
        <Route exact path='/signup' element={<Login />}></Route>
        <Route exact path='/web' element={<WebHome instanceType='companies'/>}></Route>
        <Route path='/companies/:companyId' element={<WebHome instanceType='projects'/>}></Route>
        <Route path='/companies/:companyId/projects/:projectId' element={<WebHome instanceType='projectInfo'/>}></Route>
    </Routes>
  );
}