import React, { useState } from 'react';
import './Features.css';
import Topbar from '../Topbar';
import LeftLinkContainer from '../Containers/LeftLinkContainer';

import FeatureContainer from '../Containers/FeatureContainer';

import AddAProjectImage from '../images/Features/AddAProject.png'; 
import AddAnActivityImage from '../images/Features/AddAnActivity.png';
import GenerateAnInvoiceImage from '../images/Features/GenerateInvoice.png';
import ViewAnInvoiceImage from '../images/Features/ViewInvoice.png';
import AddAnAgreementImage from '../images/Features/AddAgreement.png';
import AddAnEmployeeImage from '../images/Features/AddEmployee.png';
import EditAnEmployeeImage from '../images/Features/EditEmployee.png';
import AddACompanyImage from '../images/Features/AddCompany.png';

export default function Features() {
    const featureMaterial = {
        ['Company']: {
            title: 'Company Management',
            material: [
                {
                    id: 'addcompany',
                    title: 'Creating a Company',
                    image: AddACompanyImage,
                    paragraphs: [
                        `In the Structure In application, organization is achieved through company files. 
                        These files encompass comprehensive company details and utilize a unique file 
                        extension, namely *.stin. You're not limited in the number of companies you can establish. 
                        To initiate the process, choose "Create a new Company" on the homepage or navigate to File->Create a Company. 
                        This will trigger a prompt to input your company's information.`,
                    ]
                },
            ]
        },
        ['Projects']: {
            title: 'Project Management',
            material: [
                {
                    id: 'addproject',
                    title: 'Adding a Project',
                    image: AddAProjectImage,
                    paragraphs: [
                        `Project Management starts when you add a project. You can add a project by clicking 
                        the "+" button on the left side of the screen when you are in the "Projects" tab. 
                        You will be prompted to enter a unique project name and other information about the project. 
                        Once you have entered the information, click the "Add Project" button to add the project to your list of projects.`,
                    ]
                },
                {
                    id: 'addactivity',
                    title: 'Adding an Activity',
                    image: AddAnActivityImage,
                    paragraphs: [
                        `Activities are a way to keep track of what was done on a project. Activities can represent many things, 
                        such as a site visit, an amount of time spent on design, or a retainer. These activities are billable and 
                        can be hourly, where they have an hourly rate, or have a flat fee. You can add an activity by clicking the 
                        "Add Activity Button" under the "Activities" tab in a selected project. NOTE: Before adding an activity, 
                        you must add an employee to attach to that activity.`,
                    ]
                },
                {
                    id: 'generateinvoice',
                    title: 'Generating an Invoice',
                    image: GenerateAnInvoiceImage,
                    paragraphs: [
                        `Once you have added some activities and you want to generate an invoice to send to your client, navigate 
                        to the billing page and press "Generate an Invoice". A page will open with all the content of the invoice. 
                        This content will automatically be generated, but can be changed. You will be prompted to select the activities and billable items that 
                        you want to include in that invoice. Once you confirm your selection, you will be prompted to save the pdf invoice.`,
                    ]
                },
                {
                    id: 'viewinvoice',
                    title: 'Viewing an Invoice',
                    image: ViewAnInvoiceImage,
                    paragraphs: [
                        'Once you have generated an invoice, you can view the invoice by double clicking anywhere in that invoice\'s row in the billing section. This will open the invoice using your default PDF viewer.',
                    ]
                },
                {
                    id: 'linkagreement',
                    title: 'Linking an Agreement',
                    image: AddAnAgreementImage,
                    paragraphs: [
                        'A project may have some signed documents/agreements that you would like to keep track of. You can link these documents to a project by clicking the "Add Agreement" button under the "Agreements" tab in a selected project. You will be prompted to select the document that you want to link to the project. Once you confirm your selection, the document will be linked to the project.',
                    ]
                }
            ]
        },
        ['Employees']: {
            title: 'Employee Management',
            material: [
                {
                    id: 'addemployee',
                    title: 'Adding an Employee',
                    image: AddAnEmployeeImage,
                    paragraphs: [
                        'Employee Management starts when you add an employee. You can add an employee by clicking the "+" button on the left side of the screen when you are in the "Employees" tab. You will be prompted to enter some information about the employee. Once you have entered the information, click the "Add Employee" button to add the employee to your company. The program will generate a random unique employee ID for the employee. You can use this ID to identify the employee in the program.',
                    ]
                },
                {
                    id: 'editemployee',
                    title: 'Editing an Employee',
                    image: EditAnEmployeeImage,
                    paragraphs: [
                        'Once you have added an employee, you can select the employee in the left bar to view their information or make changes.',
                    ]
                }
            ]
        } 
    }

    const [selectedFeature, setSelectedFeature] = useState('Company');

    // Callback function to handle the clicked feature
    const handleFeatureClick = (feature) => {
        setSelectedFeature(feature);
    };


    return (
        <>
            <Topbar domain="/" />
            <LeftLinkContainer onFeatureClick={handleFeatureClick} />
            <FeatureContainer material={featureMaterial[selectedFeature]} />
        </>
    )
}