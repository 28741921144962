import './WebTopbar.css'
import logo from '../images/logo_square.png'
import aboutlogo from '../images/about.png'
import profileIcon from '../images/profileIcon.png'
import darkmodeIcon from '../images/moon-dark-theme.svg'
import lightmodeIcon from '../images/lights-sun.svg'
import { useSelector } from 'react-redux';

import React from 'react';

import { useTheme } from '../ThemeContext';
import { useNavigate } from "react-router-dom";

export default function Topbar(props) {
    // const [isDarkMode, setIsDarkMode] = useState(true);

    // // Toggle function to switch between light and dark modes
    // const toggleDarkMode = () => {
    //     props.setLightMode(!isDarkMode ? "dark" : "light")
    //     setIsDarkMode(!isDarkMode);
    // };

    const { theme, toggleTheme } = useTheme();

    const navigate = useNavigate();

    const userData = useSelector((state) => state.userData);

    function navigateToHome() {
        navigate('/web');
    }

    return (
        <div className={`MainTopbar ${theme === "light" ? 'light-background-layer2 ' : 'dark-background-layer2'}`}>
            <div className="TopbarLeft">
                <a onClick={navigateToHome} className="TopbarLeftItem">
                    <img src={logo} alt="st" className="logo"></img>
                </a>
            </div>
            <div className="TopbarRight">
                <a href="/download" className={`TopbarRightItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>
                    <img src={aboutlogo} className={`topbarItemIcon ${theme === "light" ? 'light-image ' : 'dark-image'}`} alt="About"></img>
                </a>
                <a onClick={toggleTheme} className={`TopbarRightItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>
                    <div className={`profileIcon ${theme === "light" ? 'dark-background-layer2' : 'light-background-layer2'}`}>
                        <img src={theme === "dark" ? darkmodeIcon : lightmodeIcon} className={`topbarItemIcon ${theme === "light" ? 'dark-image ' : 'dark-image'}`} alt="About"></img>
                    </div>
                </a>
                <a className={`TopbarRightItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>
                    <div className={`profileIcon ${theme === "light" ? 'dark-background-layer2' : 'light-background-layer2'}`}>
                        <img src={userData.user ? userData.user.picture : profileIcon} className={`topbarItemIcon ${userData.user ? 'imageIcon' : 'dark-image'}`} alt="profile"></img>
                    </div>
                </a>
            </div>
        </div>                  
    )
}