import React, { useEffect, useState } from 'react';
import './LeftHierarchy.css';

import { useTheme } from '../../ThemeContext';

import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import HierarchyItem from './HierarchyItem';

import CompanyImage from '../../images/company.png'
import ProjectImage from '../../images/projectsImage.png'

export default function LeftHierarchy() {
  const { theme } = useTheme();

  const navigate = useNavigate();

  const userData = useSelector((state) => state.userData);

  const [HierarchyItems, setHierarchyItems] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  function selectItem(data, type, parent) {
    console.log(data);
    setSelectedItem(data);

    if (type === 'companies') {
        navigate(`/companies/${data}`);
    } else if (type === 'projects') {
        navigate('/companies/' + parent +  '/projects/' + data);
    }
  }

  useEffect(() => {
    if (!userData || !userData.user) {
      return;
    }

    const newElements = userData.user['companies']
      ? Object.entries(userData.user['companies']).map(([key, data]) => (
          <HierarchyItem
            indent="0px"
            isSelected={key === selectedItem}
            onSelect={selectItem}
            key={key}
            title={data.Name}
            children={userData.user['companies'][key]['projects']}
            id={key}
            type="companies"
            image={CompanyImage}
            childImage={ProjectImage}
          />
        ))
      : null;

    setHierarchyItems(newElements);
  }, [userData, selectedItem]);

  return <div className='HierarchyContainer'>{HierarchyItems}</div>;
}
