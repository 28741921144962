import logo from './images/logo_square.png'
import aboutlogo from './images/about.png'
import hamburgerIcon from './images/hamburger.svg'
import exitIcon from './images/exit.svg'
import darkmodeIcon from './images/moon-dark-theme.svg'
import lightmodeIcon from './images/lights-sun.svg'
import React, {useState, useEffect} from 'react';

import { useTheme } from './ThemeContext';

export default function Topbar(props) {
    const { theme, toggleTheme } = useTheme();

    const [isMobile, setIsMobile] = useState(false);
    const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

    const toggleHamburgerMenu = () => {
        setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
        <div className={`Topbar ${theme === "light" ? 'light-background-layer2' : 'dark-background-layer2'}`}>
        <div className={`MainTopbar ${theme === "light" ? 'light-background-layer2' : 'dark-background-layer2'}`}>
            <div className="TopbarLeft">
                <a href={props.domain} className="TopbarLeftItem">
                    <img src={logo} alt="st" className="logo"></img>
                    <div className={`logoText ${theme === "light" ? 'light-background-layer2' : 'dark-background-layer2'}`}>Structure In</div>
                </a>
            </div>
            {!isMobile && <div className="TopbarRight">
                <a href="/learn" className={`TopbarRightItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>Demo</a>
                {/* <a href="/download" className={`TopbarRightItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>Download</a> */}
                <a href="/learn" className={`TopbarRightItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>Learn</a>
                {/* <a onClick={toggleDarkMode} className={`TopbarRightItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>
                    <div className={`profileIcon ${theme === "light" ? 'dark-background-layer2' : 'light-background-layer2'}`}>
                        <img src={isDarkMode ? darkmodeIcon : lightmodeIcon} className={`topbarItemIcon ${theme === "light" ? 'dark-image ' : 'dark-image'}`} alt="About"></img>
                    </div>
                </a> */}
                <a href="/login" className={`TopbarRightItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>Login</a>
                <a href="/signup" className={`TopbarRightItem LoginButton`}>Get Started</a>
                <a href="/download" className={`TopbarRightItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>
                    <img src={aboutlogo} className={`topbarItemIcon ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-image ' : 'dark-image'}`} alt="About"></img>
                </a>
                <a onClick={toggleTheme} className={`TopbarRightItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>
                    <div className={`profileIcon ${theme === "light" ? 'dark-background-layer2' : 'light-background-layer2'}`}>
                        <img src={theme === "dark" ? darkmodeIcon : lightmodeIcon} className={`topbarItemIcon ${theme === "light" ? 'dark-image ' : 'dark-image'}`} alt="About"></img>
                    </div>
                </a>
            </div>}

            {isMobile && <div className="TopbarRight">
                <button onClick={toggleHamburgerMenu} className={`HamburgerButton ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>
                    <img src={isHamburgerMenuOpen ? exitIcon : hamburgerIcon} alt='a' className={`HamburgerTopbar ${theme === "light" ? 'light-image ' : 'dark-image'}`}></img>
                </button>
            </div>}

        </div>

        {isHamburgerMenuOpen && <div className="HamburgerMenu">
            <a href="/learn" className={`HamburgerMenuItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>Demo</a>
            {/* <a href="/download" className={`TopbarRightItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>Download</a> */}
            <a href="/learn" className={`HamburgerMenuItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>Learn</a>
            {/* <a onClick={toggleDarkMode} className={`TopbarRightItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>
                <div className={`profileIcon ${theme === "light" ? 'dark-background-layer2' : 'light-background-layer2'}`}>
                    <img src={isDarkMode ? darkmodeIcon : lightmodeIcon} className={`topbarItemIcon ${theme === "light" ? 'dark-image ' : 'dark-image'}`} alt="About"></img>
                </div>
            </a> */}
            <a href="/login" className={`HamburgerMenuItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>Login</a>
            <a href="/signup" className={`HamburgerMenuItem LoginButton`}>Get Started</a>
            <div className="HamburgerMenuGroup">
                <a href="/download" className={`TopbarRightItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>
                    <img src={aboutlogo} className={`topbarItemIcon ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-image ' : 'dark-image'}`} alt="About"></img>
                </a>
                <a onClick={toggleTheme} className={`TopbarRightItem ${theme === "light" ? 'light-primary-color-hover light-primary-color-active light-background-layer2' : 'dark-primary-color-hover dark-primary-color-active dark-background-layer2'}`}>
                    <div className={`profileIcon ${theme === "light" ? 'dark-background-layer2' : 'light-background-layer2'}`}>
                        <img src={theme === "dark" ? darkmodeIcon : lightmodeIcon} className={`topbarItemIcon ${theme === "light" ? 'dark-image ' : 'dark-image'}`} alt="About"></img>
                    </div>
                </a>
            </div>
        </div>}

        </div>                
    )
}