import './Home.css';
import Topbar from '../Topbar.js'
import MainSection from '../Containers/MainSection';
import TrySection from '../Containers/TrySection';
import PlansSection from '../Containers/PlansSection';

import React, { useState } from 'react';

function Home(props) {
  const [colorMode, setColorMode] = useState("dark");

  const toggleColorMode = (data) => {
      setColorMode(data);
      props.setMode(data);
  };

  return (  
    <>
      <Topbar domain="/" theme={colorMode} setLightMode={toggleColorMode} />
      <MainSection />
      <TrySection />
      <PlansSection />
    </>
  );
}

export default Home;