import React, {useState, useEffect} from 'react';
import './TabSelector.css';

import { useTheme } from '../../ThemeContext';

export default function ContainerTopBar(props) {
    const { theme } = useTheme();

    const [tabs, setTabs] = useState(null);

    const [selectedTab, setSelectedTab] = useState(null);

    function selectTab(data) {
        setSelectedTab(data.label);
        data.selectTab(data.label);
    }

    useEffect(() => {
        const newElements = props.tabs?.map((data) => (
            <button key={data.label} id={data.label} onClick={() => selectTab(data)} className={`TabButton ${theme === "light" ? selectedTab === data.label ? 'light-selected' : '' : selectedTab === data.label ? 'dark-selected' : ''} ${theme === "light" ? 'light-border light-background-layer1 light-color1-hover light-color1-active' : 'dark-border-light dark-background-layer-1 dark-color1-hover dark-color1-active'}`}>
                {data.label}
            </button>
        ));

        console.log(newElements)

        setTabs(newElements);
    }, [props.tabs, theme, selectedTab]);

    useEffect(() => {
        if (props.tabs) {
            selectTab(props.tabs[0]);
        }
    }, [props.tabs]);

    return (
        <div className={`TabTopbar ${theme === "light" ? 'light-background-layer1 light-box-shadow' : 'dark-background-layer1 dark-box-shadow'}`}>
            <div className='TabContainer'>
                {tabs}
            </div>
            <button className='AddTab light-theme-color-orange light-theme-color-orange-hover light-theme-color-orange-active'>
                +
            </button>
        </div>
    )
}