import React, {useState} from 'react';
import './AddPopup.css';

import Options from '../../InputWidgets/Options';
import Dropdown from '../../InputWidgets/Dropdown';
import DoubleInput from '../../InputWidgets/DoubleInput';
import TelephoneInput from '../../InputWidgets/TelephoneInput';
import DescriptionInput from '../../InputWidgets/DescriptionInput';
import TextInput from '../../InputWidgets/TextInput';
import DateInput from '../../InputWidgets/DateInput';
import CreateInvoice from '../../ContainerWidgets/CreateInvoice';

import { useTheme } from '../../ThemeContext';

import {getIDFromLink} from '../../Classes/ParseLink';
import ListSelector from '../../InputWidgets/ListSelector';

import { useBlur } from '../../BlurContext';


export default function AddPopup(props) {
    const { theme } = useTheme();

    const { blur, toggleBlur } = useBlur();

    const [selectedOptions, setSelectedOptions] = useState({});
    const [selectedList, setSelectedList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [formData, setFormData] = useState({});

    const FormObjects = [].concat(
        ...Object.entries(props.material).map(([pageKey, pageData]) => (
          <div className='PopupPage' key={pageKey}>
            {pageData.map((data, index) => (
              <React.Fragment key={`${pageKey}_${index}`}>
                {data.divider && <div className="Divider" key={'Divider' + data.label}>{data.dividerText}</div>}
      
                {data.type === "text" && <TextInput key={data.label} id={data.label} label={data.label} theme={theme} />}
                {data.type === "options" && <Options key={data.label} id={data.label} options={data.options} title={data.label} OptionSelected={SelectOption} />}
                {data.type === "dropdownlist" && <Dropdown key={data.label} id={data.label} options={data.options} title={data.label} DropdownSelected={SelectDropdown} />}
                {data.type === "description" && <DescriptionInput key={data.label} id={data.label} label={data.label} theme={theme} />}
                {data.type === "date" && <DateInput key={data.label} id={data.label} label={data.label} theme={theme} />}
                {data.type === "datetime" && (
                  <>
                    <div key={data.label} className={`PopupLabel ${theme === "light" ? 'light-background-layer2' : 'dark-background-layer2'}`}>
                      {data.label}
                    </div>
                    <input
                      type="datetime-local"
                      id={data.label}
                      className={`PopupDateInput ${theme === "light" ? 'dark-border light-background-layer2' : 'light-border dark-background-layer2 PopupDateInputDark'}`}
                    />
                  </>
                )}
                {data.type === "number" && <DoubleInput key={data.label} id={data.label} options={data.options} label={data.label} title={data.label} />}
                {data.type === "phone" && <TelephoneInput key={data.label} id={data.label} label={data.label} options={data.options} title={data.label} theme={theme} />}
                {data.type === "invoice" && (
                  <div className='InvoiceContainer' key={data.label}>
                    <ListSelector key={data.label} id={data.label} items={data.items} title={data.label} ListSelected={SelectList} />
                    <CreateInvoice key={data.label} id={data.label} items={selectedList} title={data.label} theme={theme} />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        ))
      );
      
    
      
      console.log(FormObjects)


    function toggleClose() {
        const elem = document.getElementById(props.id);
        elem.classList.remove("show-popup");
        toggleBlur();

        setCurrentPage(1)
    }

    function closePopup() {
        if (currentPage >= Object.keys(props.material).length) {
            toggleClose();
            return;
        }

        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            return;
        }

        toggleClose();
    }

    function ConfirmAddPopup() {
        const tempFormData = { ...formData };
        
        const pageData = Object.values(props.material)[currentPage - 1];

        pageData.forEach((data, index) => {
            console.log(data)
            if (data.type === "options" || data.type === "dropdownlist") {
                tempFormData[data.label] = selectedOptions[data.label];
            } else if (data.type === "invoice") {
                tempFormData['invoice'] = selectedList;
            } else {
                console.log(data.label)
                tempFormData[data.label] = document.getElementById(data.label).value;
            }
        });

        setFormData((prevFormData) => ({
            ...prevFormData,
            ...tempFormData,
            companyID: getIDFromLink("companies"),
            projectID: getIDFromLink("projects"),
        }));

        console.log(currentPage, Object.keys(props.material).length)

        if (currentPage < Object.keys(props.material).length) {
            console.log(currentPage)
            setCurrentPage(currentPage + 1);
            return;
        }
      
        tempFormData.companyID = getIDFromLink("companies");
        tempFormData.projectID = getIDFromLink("projects");

        props.ConfirmAddPopup(tempFormData);
      
        console.log('Closing popup')

        closePopup();
    }

    function SelectOption(id, selectedOption) {
        const updatedSelectedOptions = {
            ...selectedOptions,
            [id]: selectedOption,
        };
        setSelectedOptions(updatedSelectedOptions);
        console.log(id, selectedOption)
    }

    function SelectDropdown(id, selectedOption) {
        const updatedSelectedOptions = {
            ...selectedOptions,
            [id]: selectedOption,
        };
        setSelectedOptions(updatedSelectedOptions);
        console.log(id, selectedOption)
    }

    function SelectList(selectedOptions) {
        setSelectedOptions(selectedOptions);
    }

    return (
        <div className={`PopupContainer ${theme === "light" ? 'light-background-layer2' : 'dark-background-layer2 dark-box-shadow'}`} id={props.id}>
            <div className='TitleCloseContainer'>
                <div className='PopupTitle'>{props.title}</div>
                <button className={`closeButton ${theme === "light" ? 'light-background-layer2 light-primary-color-hover light-primary-color-active' : 'dark-background-layer2 dark-primary-color-hover dark-primary-color-active'}`} onClick={toggleClose}>x</button>
            </div>
            <div className={`FormContainer ${theme === "light" ? 'light-background-layer2' : 'dark-background-layer2'}`}>
                {FormObjects[currentPage - 1]}
            </div>
            <div className='PopupButtonContainer'> 
                <button onClick={closePopup} className={`PopupButton ${theme === "light" ? 'light-background-layer3 light-primary-color-hover light-primary-color-active' : 'dark-background-layer3 dark-primary-color-hover dark-primary-color-active'}`}>{FormObjects ? currentPage === 1 ? 'Cancel' : 'Back' : 'Cancel'}</button>
                <button onClick={ConfirmAddPopup} className={`PopupButton ${theme === "light" ? 'light-theme-color-orange light-theme-color-orange-hover light-theme-color-orange-active' : 'dark-theme-color-orange light-theme-color-orange-hover light-theme-color-orange-active'}`}>{FormObjects ? currentPage === FormObjects.length ? props.title : 'Next' : props.title}</button>
            </div>
        </div>
    )
}