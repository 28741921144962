import axios from 'axios';

const apiKey = process.env.REACT_APP_CLIENT_ID;

const apiEndpoint = 'http://localhost:3001/api/';
const apiEndpointLogin = apiEndpoint + 'login';
const apiEndpointAuth = apiEndpoint + 'auth';
const apiEndpointFetchAllData = apiEndpoint + 'getData';
const apiEndpointFetchProjects = apiEndpoint + "getProjects";
const apiEndpointFetchEmployees = apiEndpoint + "getEmployees";
const addProjectEndpoint = apiEndpoint + "addProject";
const addCompanyEndpoint = apiEndpoint + "addCompany";
const addEmployeeEndpoint = apiEndpoint + "addEmployee";
const addActivityEndpoint = apiEndpoint + "addActivity";
const addInvoiceEndpoint = apiEndpoint + "addInvoice";

export async function auth() {
  return new Promise((resolve, reject) => {
    axios
      .get(apiEndpointAuth, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
      .then((response) => {
        console.log('Server response:', response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
  });                 
}

async function CleanCompanyData(data) {
  if (!data) {return null;}
  if (!data.companies) {return null;}

  for (const companyID in data.companies) {
    data.companies[companyID].id = companyID;
  }

  return data;
}

async function CleanProjectData(data) {
  if (!data) {return null;}
  
  const newData = {}

  for (const projectID in data) {
    newData[projectID] = data[projectID];
    newData[projectID].id = projectID;
  }

  return data;
}

export async function FetchAllData() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };
  
    try {
      const response = await axios.get(apiEndpointFetchAllData, config);
      console.log('Server response FETCHALLDATA:', response.data);

      const cleanedData = await CleanCompanyData(response.data);

      console.log('Cleaned data:', cleanedData);

      return cleanedData;
    } catch (error) {
      console.error('Error:', error);
      throw error
    }
}

export async function FetchProjects(companyId) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  try {
    const response = await axios.get(apiEndpointFetchProjects + '/' + companyId, config);
    console.log('Server response FETCHALLDATA:', response.data);

    const cleanedData = await CleanProjectData(response.data);

    console.log('Cleaned data:', cleanedData)

    return cleanedData;
  } catch (error) {
    console.error('Error:', error);
    throw error
  }               
}

export async function FetchCompanyEmployees(companyId) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  try {
    const response = await axios.get(apiEndpointFetchEmployees + '/' + companyId, config);
    console.log('Server response FetchCompanyEmployees:', response.data);

    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error
  }   
}

export async function addProject(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(addProjectEndpoint, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
      .then((response) => {
        console.log('Server response:', response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
  });                   
}

export async function addCompany(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(addCompanyEndpoint, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
      .then((response) => {
        console.log('Server response:', response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
  });                   
}

export async function addEmployee(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(addEmployeeEndpoint, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
      .then((response) => {
        console.log('Server response:', response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
  });
}

export async function addActivity(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(addActivityEndpoint, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
      .then((response) => {
        console.log('Server response:', response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
  });
}

export async function addInvoice(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(addInvoiceEndpoint, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
      .then((response) => {
        console.log('Server response:', response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
  });
}