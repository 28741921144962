import './App.css';
import Main from './Main';
import Contact from './Containers/Contact';
import './light-mode.css'

import React from 'react';

import { useTheme } from './ThemeContext';
import { useBlur } from './BlurContext';


function App() {
  // const [colorMode, setColorMode] = useState("dark");
  const { theme } = useTheme();

  const { blur } = useBlur();

  // const toggleColorMode = (data) => {
  //     setColorMode(data);
  //     console.log(colorMode);
  // };

  return (
    <>
      <div className={`BlurBackground ${theme === "light" ? 'blur-background-light' : 'blur-background-dark'} ${blur ? 'BlurBackgroundEnabled' : ''}`}/>
      <div className={`MainGrid ${theme === "light" ? 'light-background-layer1' : 'dark-background-layer1'}`}>
        <Main/>
      </div>
      <Contact/>
    </>
  );
}

export default App;
