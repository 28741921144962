import React, {useEffect, useState} from 'react';
import WorkspaceContainer from '../../ContainerWidgets/WorkspaceContainer';

import './HomeContainer.css';
import ContainerTopBar from './ContainerTopBar';

import { useSelector } from 'react-redux';

import companyImage from '../../images/company.svg';
import projectImage from '../../images/projects.svg';

import {getIDFromLink} from '../../Classes/ParseLink';

import TabSelector from './TabSelector';
import InfoContainer from '../../ContainerWidgets/InfoContainer';

import TableWidget from '../../ContainerWidgets/TableWidget';

import {TableEmployees, TableActivities, TableInvoices} from '../../Classes/DataManager'
import { getCompanyTabs, getProjectTabs, EmployeeAddOptionsMaterial, ProjectAddOptionsMaterial, ActivityAddOptionsMaterial, InvoiceAddOptionsMaterial} from '../../StaticData/Material';

export default function MainContainer(props) {

    const userData = useSelector((state) => state.userData);

    const [instances, setInstances] = useState(null);

    const [tab , setTab] = useState("workspace");

    const [tabs, setTabs] = useState(null); //tabs to select from

    const [Info, setInfo] = useState(null); //company/project info to display

    const [tabData, setTabData] = useState({ employeeData: null, activityData: null, invoiceData: null }); //data to display in the tab

    useEffect(() => {
        console.log(props.instanceType, userData)
        if (!userData || !props.instanceType || !userData.user) {return;}

        var instances = null;
        var newTabs = null;

        if (userData.user && userData.user['companies']) {
            const allCompanyInfo = userData.user['companies'][getIDFromLink("companies")];

            console.log(getIDFromLink("projects"))
            console.log(allCompanyInfo)

            if (allCompanyInfo && allCompanyInfo['projects'] && allCompanyInfo['projects'][getIDFromLink("projects")]) {
                console.log(allCompanyInfo['projects'][getIDFromLink("projects")]['activities'])
                setTabData({
                    ...tabData,
                    employeeData: TableEmployees(allCompanyInfo['employees']),
                    activityData: TableActivities(allCompanyInfo['projects'][getIDFromLink("projects")]['activities'], allCompanyInfo['employees']),
                    invoiceData: TableInvoices(allCompanyInfo['projects'][getIDFromLink("projects")]['invoices'])
                });
            } else if (allCompanyInfo) {
                setTabData({
                    ...tabData,
                    employeeData: TableEmployees(allCompanyInfo['employees']),
                    activityData: TableActivities(null, null),
                    invoiceData: TableInvoices(null)
                });
            }
        }

        if (props.instanceType === "projects") {
            if (!userData.user['companies']) {return;}
            if (!userData.user['companies'][getIDFromLink("companies")]) {return;}

            const allCompanyInfo = userData.user['companies'][getIDFromLink("companies")];
            const newCompanyInfo = {};

            //Company fields to display
            newCompanyInfo['title'] = allCompanyInfo['Name'];

            newCompanyInfo['field1'] = {
                'Company address': allCompanyInfo['Address'],
                'Company email address': allCompanyInfo['Email'],
                'Company phone number': allCompanyInfo['Phone'],
                divider: false,
            }

            newCompanyInfo['field2'] = {
                'dtitle': 'Company logo',
                image: allCompanyInfo['Logo'],
                divider: true,
            }

            newCompanyInfo['field2'] = {
                'dtitle': 'Tax information',
                'Tax classification': allCompanyInfo['TaxClassification'],
                'EIN': allCompanyInfo['EIN'],
                divider: true,
            }

            setInfo(newCompanyInfo);

            console.log(newCompanyInfo)

            instances = userData.user['companies'][getIDFromLink("companies")][props.instanceType]

            if (!instances) {
                
            }
            
            newTabs = getCompanyTabs(selectTab);
        } else if (props.instanceType === "projectInfo") {
            if (!userData.user['companies']) {return;}
            if (!userData.user['companies'][getIDFromLink("companies")]) {return;}
            if (!userData.user['companies'][getIDFromLink("companies")]['projects']) {return;}

            const allProjectInfo = userData.user['companies'][getIDFromLink("companies")]['projects'][getIDFromLink("projects")];
            const newProjectInfo = {};

            console.log(allProjectInfo)

            //Project fields to display
            newProjectInfo['title'] = allProjectInfo['Name'];

            newProjectInfo['field1'] = {
                'Project address': allProjectInfo['Address'],
                'Project description': allProjectInfo['Description'],
                'Project type': allProjectInfo['Type'],
                'Project start date': allProjectInfo['StartDate'],
                divider: false,
            }

            console.log(newProjectInfo)
            
            newTabs = getProjectTabs(selectTab);

            setInfo(newProjectInfo);
        } else {
            instances = userData.user[props.instanceType];
        }

        console.log(instances)
        console.log(newTabs)

        setInstances(instances);
        setTabs(newTabs);
    }, [userData, props.instanceType, window.location.href]);

    function selectTab(label) {
        setTab(label);
    }

    return (
        <div className={`HomeContainer ${props.theme === "light" ? 'light-background-layer3' : 'dark-background-layer3'}`}>
            <ContainerTopBar theme={props.theme} title={props.instanceType === "projects" || props.instanceType === "projectInfo" ? userData?.user?.companies?.[getIDFromLink("companies")]?.Name || "Select a company to get started" : "Select a company to get started"} />
            { props.instanceType !== "companies" && <div className={`HomeTabs ${props.theme === "light" ? 'light-background-layer3 light-border' : 'dark-background-layer3 dark-border-light'}`}>
                <TabSelector  tabs={tabs}/>
                {tab === "Projects" && <WorkspaceContainer theme={props.theme} instances={instances} instanceType={'projects'} image={projectImage} title="Projects" addOptions={ProjectAddOptionsMaterial} addOptionsEnabled={true}/>}
                {tab === "Company Information" && <InfoContainer instances={Info} />}
                {tab === "Project Information" && <InfoContainer instances={Info} />}
                {tab === "Employees" && <TableWidget instances={tabData.employeeData} addOptions={EmployeeAddOptionsMaterial} addOptionsEnabled={true}/>}
                {tab === "Activities" && <TableWidget instances={tabData.activityData} addOptions={ActivityAddOptionsMaterial} addOptionsEnabled={true}/>}
                {tab === "Invoices" && <TableWidget instances={tabData.invoiceData} addOptions={InvoiceAddOptionsMaterial} addOptionsEnabled={true}/>}
            </div> }
            {props.instanceType === "companies" && <WorkspaceContainer theme={props.theme} instances={instances} instanceType={props.instanceType} image={companyImage}/>}
        </div>
    )
}