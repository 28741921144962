
//Only handles USA Phone Numbers
export default function GetTelephoneValue(id) {
    var telephone = document.getElementById(id).value;
    if (!telephone) {
        return "Error getting phone number"
    }
    
    telephone = telephone.replace(/\D/g, '');


    if (telephone && telephone.length !== 10) {
        return "Phone number must be 10 digits"
    }

    if (telephone && telephone.length === 10) {
        return `+1${telephone}`;
    }

    return "Error getting phone number";
}