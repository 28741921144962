import './TrySection.css'
import React from 'react';
import DownloadButton from '../Buttons/DownloadButton';
import downloadImage from '../images/download-black.png';
import arrowImage from '../images/arrow.svg';
import SignUpImage from '../images/signup.svg';

import { useTheme } from '../ThemeContext';

export default function TrySection() {
    const { theme } = useTheme();

    return (
        <div className={`TrySection ${theme === "light" ? 'light-background-layer3' : 'dark-background-layer3'}`}>
            <div className="TrySectionTitle">Try Structure In at No Cost.</div>
            <div className="DownloadContainer">
                <DownloadButton text="Get started for free" link="/signup" width="300px" image={arrowImage}/>
            </div>
        </div>
    )
}