import React, {useState} from 'react';
import './HexOptions.css';

import { useTheme } from '../ThemeContext';

export default function HexOptions(props) {
    const { theme } = useTheme();

    const [selectedOption, setSelectedOption] = useState(props.options.length > 0 ? props.options[0].label : null);

    const allOptions = props.options.map((data) => (
        <div className={`HexOption ${selectedOption === data.label ? 'HexOptionSelected' : ''}`} id={data.label} onClick={() => SelectOption(data.label)}>
            <div className={`${theme === 'light' ? 'HexShape1Light' : 'HexShape1Dark'}`}/>
            <div className={`HexShapeMiddle ${theme === 'light' ? 'light-background-layer2' : 'dark-background-layer2'}`}> 
                <img src={data.image} alt={data.label} className={`HexOptionImage ${theme === 'light' ? '' : 'dark-image'}`} />
                {data.label} 
            </div>
            <div className={`${theme === 'light' ? 'HexShape2Light' : 'HexShape2Dark'}`}/>
        </div>
    ));

    function SelectOption(id) {
        setSelectedOption(id);
        props.OptionSelected(props.id, id);
    }

    return (
        <div className='HexContainer'>
            <div className="HexOptionsTitle"> {props.title} </div>
            <div className="HexOptionsContainer">
                {allOptions}
            </div>
        </div>
    )
}