import React, {useState, useEffect} from 'react';

import AddMenu from '../StructureInWeb/Containers/AddMenu';

export default function TableTopbar(props) {
    const [MenuVisible, setMenuVisible] = useState(false);

    function AddMenuActivate() {
        setMenuVisible(!MenuVisible);
    }

    return (
        <div className='TableTopbar'>
            <div className='TableTitle'>{props.title}</div>
            {props.addOptionsEnabled && <button onClick={AddMenuActivate} className='AddButton light-theme-color-orange light-theme-color-orange-hover light-theme-color-orange-active'>+
                {MenuVisible && <AddMenu material={props.addOptions} width='180px'/>}
            </button>}
        </div>
    )
}