import { useTheme } from '../ThemeContext';

import './InfoDisplay.css';

export default function InfoDisplay(props) {
    const { theme } = useTheme();

    return props.displayed ? (
        <div className={`InfoDisplayContainer ${theme === 'light' ? 'light-background-layer2' : 'dark-background-layer2'}`}>
            <div className={`TriangleBefore ${theme === 'light' ? 'light-triangle' : ' dark-triangle'}`} />
            <div className='InfoDisplayText'>{props.info}</div>
        </div>
    ) : null
}