import React from 'react';

import SubFeature from './SubFeature';

import './FeatureContainer.css';

export default function FeatureContainer(props) {

    const SubFeaturesList = props.material.material.map((data) => (
        <SubFeature title={data.title} image={data.image} paragraphs={data.paragraphs} id={data.id} key={data.id}/>
    ));

    return (
        <div className="FeatureContainer">
            <div className="FeatureTitle" >{props.material.title}</div>
            {SubFeaturesList}
        </div>
    )
}