import React, {useState} from 'react';
import './DoubleInput.css';

import { useTheme } from '../ThemeContext';

export default function DoubleInput(props) {
    const { theme } = useTheme();

    const [doubleValue, setDoubleValue] = useState('0.00');

    function handleNumbers(event) {
        var inputValue = event.target.value;
        
        if (!inputValue) {
            setDoubleValue('0.00');
            return;
        }

        // Remove any non-numeric or non-decimal characters
        inputValue = inputValue.replace(/[^0-9.]/g, '');

        // Ensure only one decimal point
        const decimalCount = (inputValue.match(/\./g) || []).length;
        if (decimalCount > 1) {
            inputValue = inputValue.slice(0, inputValue.lastIndexOf('.'));
        }

        // Format to two decimal places
        var parts = null;

        if (inputValue.includes('.')) {
            parts = inputValue.split('.');
            parts[1] = parts[1].slice(0, 2); // Only take two decimal places    
            if (!parts[0]) {
                setDoubleValue(inputValue);
                console.log("NOTHING BEFORE DECIMAL POINT")
                return;
            }
            inputValue = parts.join('.');
        } else {
            // Add .00 if there is no decimal point
            inputValue += '.00';
            parts = inputValue.split('.');
            parts[1] = parts[1].slice(0, 2); // Only take two decimal places
            inputValue = parts.join('.');
        }

        // Remove leading zeros in the integer part
        if (inputValue.includes('.')) {
            let integerPart = inputValue.split('.')[0];
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas
            const decimalPart = inputValue.includes('.') ? '.' + parts[1] : '';
            inputValue = integerPart + decimalPart;
        }

        setDoubleValue(inputValue);
    }

    function displayNumber(event) {
        var inputValue = event.target.value;

        // Remove any non-numeric or non-decimal characters
        inputValue = inputValue.replace(/[^0-9.]/g, '');
        
        setDoubleValue(inputValue);
    }

    return (
        <>
            <div key={props.label} className={`PopupLabel ${theme === "light" ? 'light-background-layer2' : 'dark-background-layer2'}`}>{props.title}</div>
            <input
                type="text"
                onInput={displayNumber}
                onBlur={handleNumbers}
                min={0}
                max={999999999999999}
                id={props.id}
                className={`PopupTextInput ${theme === "light" ? 'dark-border light-background-layer2' : 'light-border dark-background-layer2'}`}
                value={doubleValue}
            />
        </>
    );
}